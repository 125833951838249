import PropTypes from 'prop-types';
import { memo } from 'react'
import { connect } from 'react-redux'
import "./card.css"
import { Card, CardBody, Row } from 'reactstrap'
import CardFields from './CardFields'
import Icon from './Icon'
import { REJECTED_INVOICES_DETAILS } from '../../../constants/pathConstants'
import { createStructuredSelector } from 'reselect'
import { makeSelectCaseSearchValue } from '../../SearchCase/selectors'
import { compose } from 'redux';
import { useNavigate } from 'react-router-dom';

export const CardComponent = ({ cardDetail, btn, showCaseType, searchValue }) => {
  const navigate = useNavigate();
  const navigateToDetails = (caseNum) => {
    navigate(REJECTED_INVOICES_DETAILS + '?' + btoa('case='+caseNum));
  }
  return (
    <>
      <Card>
        {showCaseType && <div className='caseTypeNameRejected'>Rejected</div>}
        <CardBody style={{position:"relative"}}>
          <Row style={{padding:0,margin:0}}>
            <div className='card-case'>
              <span className='card-case-lable'>{cardDetail[1].label} :</span> <span className={(searchValue && cardDetail[0].value == searchValue) ? 'card-case-value highlight' : 'card-case-value'}>{cardDetail[0].value ? cardDetail[0].value : "-"}</span>
            </div>
            {cardDetail.length && cardDetail.map((item, index) => { if (index > 1) return <CardFields key={item.label} item={item} index={index} /> })}
          </Row>
          {btn &&
            <div className='details-view-btn'>
              <button data-testid="btn-view" className='buttonallotedcases' onClick={()=>navigateToDetails(cardDetail[1].value)}><Icon />  View</button>
            </div>}
        </CardBody>
      </Card>
    </>
  )
}

CardComponent.propTypes = {
  // cardDetail: PropTypes.array
  searchValue: PropTypes.string
}

const mapStateToProps = createStructuredSelector({
  searchValue: makeSelectCaseSearchValue()
});

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
  };
}

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps
);

export default compose(
  withConnect,
  memo
)(CardComponent);