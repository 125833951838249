import React, { useState, useContext, memo, useRef, useEffect } from 'react';
import { compose } from "redux";
import EmploymentMasterNavbar from '../EmploymentMasterPage/EmploymentMasterNavbar';
import EmpMasterButton from '../EmploymentMasterPage/EmpMasterButton';
import { ApiDispatchContext } from '../../context/ApiContext';
import { createStructuredSelector } from 'reselect';
import { makeSelectEmployee } from '../EmploymentMasterPage/selectors';
import path from '../../constants/pathConstants';
import { connect } from "react-redux";
import './AddEmployeeDetails.css';
import ErrorAlert from '../../components/ErrorAlert/Loadable';
import excel from "../../images/excel.png";
import ComingSoonPopUp from '../../components/ComingSoonPopUp';
import { addInvestigatorBulkUpload } from '../../constants/excel_template_constants';
import { handleExportClick } from '../../constants/commonFunction';
import { useNavigate } from 'react-router-dom';
import AddEmployee from './AddEmployee/AddEmployee';
export function AddInvestigator({ employee }) {
  const fileInputRef = useRef(null);
  const [investigatorsCountArray, setInvestigatorsCountArray] = useState([0]);
  const [investigatorsList, setInvestigatorsList] = useState([]);
  const [profileFile, setProfileFile] = useState("");
  const [disableSubmit, setDisableSubmit] = useState(true);
  const [isEmptyField, setIsEmptyField] = useState(true);
  const [modal, setModal] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [modalBody, setModalBody] = useState("");
  const toggle = () => setModal(!modal);
  const [open, setOpen] = useState(false)
  const comingToggle = ()=>setOpen(!open)
  const api = useContext(ApiDispatchContext);
  
  const navigate = useNavigate();

  const addAnother = () => {
    const currentCount = investigatorsCountArray?.length;
    setInvestigatorsCountArray([...investigatorsCountArray, currentCount]);
  };

  const removeAnother = (index) => {
    const tempArray = [...investigatorsCountArray];
    const copyOfInvestigatorsList = [...investigatorsList];
    copyOfInvestigatorsList.splice(index, 1);
    tempArray.splice(index, 1);
    setInvestigatorsList(copyOfInvestigatorsList);
    setInvestigatorsCountArray(tempArray);
  }

  const checkStateAndCityField = (postion, value) => setIsEmptyField(value);

  const profilePicHandler = (info) => {
    setProfileFile(info);
  }

  const handleButtonClick = () => {
    fileInputRef?.current?.click();
  };

  const handleFileChange = (event) => {
    const selectedFile = event?.target?.files[0];
    const formData = new FormData();
    formData.append('file', selectedFile);
    api.uploadBulkUpload(formData).then(data=>{
      if(data && data.data.message){
        navigate(path.EMPLOYEMENT_SUCCESS + "?" + btoa("title="+data?.data?.message));
      }
    }).catch(err=>{
      setModalTitle("Error");
      if (err && err?.response && err?.response?.data) {
        setModalBody(err?.response?.data?.message);
      } else {
        setModalBody('Please try after sometime.');
      }
      toggle();
    })
  };

  const getInvestigatorDetails = (data, i) => {
    const copyOfData = [...investigatorsList];
    copyOfData[i] = data;
    setInvestigatorsList(copyOfData);
  }

  const addInvesigatorData = (data) => {
    api.addEditInvesigator(data).then(data => {
      if (data?.data) {
        if (data?.data?.success === true) {
          navigate(path.EMPLOYEMENT_SUCCESS + "?" + btoa("title=New Investigator Added"));
        } else {
          if (data?.data?.message) {
            setModalTitle("Error");
            setModalBody(data?.data?.message);
            toggle();
          } else {
            setModalTitle("Error");
            setModalBody('Please try after sometime.');
            toggle();
          }
        }
      } else {
        setModalTitle("Error");
        setModalBody('Please try after sometime.');
        toggle();
      }
    }).catch(err => {
      setModalTitle("Error");
      if (err && err?.response && err?.response?.data) {
        setModalBody(err?.response?.data?.message);
      } else {
        setModalBody('Please try after sometime.');
      }
      toggle();
    })
  }

  const profileImgUpload = (investigatorsInfo) => {
    const formData = new FormData();
    formData.append('file', profileFile);
    if(profileFile) {
      api.uploadDocuments(formData).then(data=>{
        if(data?.data?.success){
          investigatorsInfo.investigators[0].profileImage.profile_image_path = data?.data?.data?.document_path;
          investigatorsInfo.investigators[0].profileImage.profile_image_size = data?.data?.data?.document_size;
          investigatorsInfo.investigators[0].profileImage.profile_image_name = data?.data?.data?.document_name;
          addInvesigatorData(investigatorsInfo);
        } else {
          setModalTitle("Error");
          if(data?.data?.error && data?.data?.message){
            setModalBody(data?.data?.message)
          }
          else{
            setModalBody('Please try after sometime');
          }
          toggle();
          }
      }).catch(err=>{
        setModalTitle("Error");
        if (err && err?.response && err?.response?.data && err?.response?.data?.message) {
          setModalBody(err.response.data.message);
        } else {
          setModalBody('Please try after sometime.');
        }
        toggle();
      });
    } else {
      delete investigatorsInfo?.investigators[0]?.profileImage;
      addInvesigatorData(investigatorsInfo);
    }
  }

  const onSubmit = () => {
    let error = false;
    
    if(isEmptyField) {
      toggle();
      setModalTitle("Error");
      setModalBody("Select both the state and city");
    } else if(!error) {
      const data = {
        "action": "create",
        "investigators": investigatorsList.flatMap(investigators =>
          investigators.map(investigator => ({
            investigatorName: investigator?.investigatorName,
            location: investigator?.location,
            email: investigator?.email,
            mobileNumber: investigator?.mobileNumber,
            status: investigator?.status,
            profileImage: {
              profile_image_size: "",
              profile_image_path: "",
              profile_image_name: "",
            }
          }))
        )
      }
      profileImgUpload(data);
    }
  }

  useEffect(() => {
      // Function to check conditions for all investigators in the list
      const checkConditionsForAllInvestigators = (investigatorsList) => {
        return investigatorsList.some(investigators => {
            return investigators.some(investigator => {
                return (investigator.investigatorName.length < 3 ||
                        investigator.email.length < 8 ||
                        investigator.mobileNumber.length < 10);
            });
        });
      }

      // Check conditions for all investigators in the list
      const checkEmptyField = checkConditionsForAllInvestigators(investigatorsList);
      setDisableSubmit(checkEmptyField);
  }, [investigatorsList])

  return (
    <div data-testid="AddInv" className='addEmpDetails'>
      <EmploymentMasterNavbar title="Add Investigator" backButton={path.EMPLOYMENT_MASTER} />
      <div className="addEmpDetails-body">
        {investigatorsCountArray?.map((ele, i) => (
          <AddEmployee
            vendorParentName={employee?.vendor_parent_name}
            vendorState={employee?.vendor_state}
            vendorCity={employee?.vendor_city}
            key={`${ele}-${i}`}
            index={i}
            onProfilePicHandler = {profilePicHandler}
            removeAnother={(i) => removeAnother(i)}
            investigatorDetails={getInvestigatorDetails}
            checkStateAndCityField={checkStateAndCityField}
           />
        ))}
        {investigatorsCountArray?.length < 3 && (
          <button data-testid="addAnother" className='addEmpDetails-addAnother' onClick={addAnother}>+ Add another one</button>
        )}
        <div className='addEmplDetails-bottom'>
        {investigatorsCountArray?.length < 3 && (
          <span className='addEmpDetails-or'>OR</span>
        )}
          <span className='addEmpDetails-bulk'>For bulk upload</span>
        </div>
        <button data-testid="addEmpDetails-uploadExcel" className="addEmpDetails-uploadExcel" onClick={handleButtonClick}>Upload excel <img src={excel} alt="Excel" /> </button>
        <div style={{display:"flex",justifyContent:"end"}}>
        <input
         data-testid="file-uploadExcel"
          type="file"
          ref={fileInputRef}
          style={{ display: 'none' }}
          accept=".xlsx, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
          onChange={handleFileChange}
        />
         <a className="download-template"  data-testid="download-template" onClick={()=>handleExportClick(addInvestigatorBulkUpload,"vendor_employee","vendor_employee")}>Download template</a>
        </div>
        <div className="empChangeStatusBtn-div" style={{width:'100%'}}>
          <div className="empChangeStatusBtn">
            <EmpMasterButton data-testid="save-button" disabled={disableSubmit} background={disableSubmit ? "#F7BDBE" : "#E94D51"}
              color={disableSubmit ? "#E94D51" : "#FFFFFF"} onClick={() => onSubmit()}>Submit</EmpMasterButton>
          </div>
        </div>
      </div>
      <ComingSoonPopUp open={open} toggle={comingToggle} />
      <ErrorAlert
        modal={modal}
        toggle={toggle}
        modalTitle={modalTitle}
        modalBody={modalBody}
      />
    </div>
  )
}
AddInvestigator.propTypes = {};

const mapStateToProps = createStructuredSelector({
  employee: makeSelectEmployee(),
});

export function mapDispatchToProps(dispatch) {
  return {
  };
}

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps
);

export default compose(withConnect, memo)(AddInvestigator);