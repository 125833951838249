import React from 'react';
import './index.css';

function FilterPopUp({ modal, filterToggle,children ,height, backgroundColor}) {
    return (
        <div className='filter-parent' style={{backgroundColor:backgroundColor ? backgroundColor : 'rgba(0, 0, 0, 0.5)'}}>
        <div className='filter-component'>
            <button className="filter-close" data-testid="FilterPopUptoggle" onClick={filterToggle}>
                <img src={require('../../images/close2.png')} />
            </button>
            <div style={{height:height, overflowY: "auto"}} className="filter-div">
                {children}
            </div>
        </div>
        </div>
    )
}

export default FilterPopUp