import {  memo } from 'react'
import { Navbar } from 'reactstrap';
import { createStructuredSelector } from 'reselect';
import chaseLogo from "../../images/CHASE_LOGO.svg"
import notificationBell  from "../../images/bell.svg"
import { connect } from 'react-redux';
import { compose } from 'redux';
import path from '../../constants/pathConstants';
import './navbar.css'
import { useNavigate, useLocation } from 'react-router-dom';
export function NavbarTop() {
    const navigate = useNavigate();
    const location = useLocation();

    return (
        <div className='navbar-container' style={{height:70, position:'sticky',top:0}}>
            <Navbar style={{height:'100%',alignItems:'end'}} className='top-navbar'>
                <div className='navbarTop-navbar-brand' onClick={() => location.pathname !== "/dashboard" && navigate("/dashboard")} style={location.pathname === "/dashboard" ? {alignItems:'flex-end',height:'100%'} : {alignItems:'flex-end',height:'100%', cursor:'pointer'}}>
                    <img src={chaseLogo} alt="HDFC Ergo" className="chase-logo"/>
                </div>
                <span style={{alignSelf:'flex-end'}}>
                    <img className='bell-icon' onClick={()=>navigate(path.COMING_SOON+ "?" + btoa("title=Notification"))}  src={notificationBell} alt="notification" />
                </span>
            </Navbar> 
        </div>
    )
}
NavbarTop.propTypes = {
};

const mapStateToProps = createStructuredSelector({
});

export function mapDispatchToProps(dispatch) {
    return {
    };
}

const withConnect = connect(
    mapStateToProps,
    mapDispatchToProps,
);

export default compose(withConnect, memo,)(NavbarTop);