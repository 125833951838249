import React, { memo, useEffect, useState } from 'react';
import { Accordion, AccordionBody, AccordionHeader, AccordionItem} from 'reactstrap';
import "../cardtab.css";
import toggleimg from "../../../../images/allotted_not_collapsed.png";
import Icon from '../../../OpenCases/CaseCard/Icon';
import greentick from '../../../../images/investigatorgreentick.png';
import orangetick from '../../../../images/investigatororangetick.svg';
import graytick from '../../../../images/investigatorgreytick.png';
import { getUrlParameter } from '../../../../constants/commonFunction';

const mandatoryFieldsForTP = [1, 2, 8, 12, 15, 19, 30, 33, 35, 42, 46, 47];
const mandatoryFieldsForOD = [2, 8, 10, 12, 15, 20, 26, 27, 42, 43, 46, 47];

export const CaseBlocks = memo((props) => {
  const [open, setOpen] = useState('0');
  const subProductType = getUrlParameter('subProduct');
    const toggle = (id) => {
      if (open === id) {
        setOpen('0');
      } else {
        setOpen(id);
      }
    };
    useEffect(() => {
      props?.blockOpen == true ? setOpen('1') : setOpen('0');
    },[props?.blockOpen]);
    
    return (
      <div className='assignedInvestigators Caseblocks' style={{ width: '100%' }}>
        <Accordion data-testid="CaseBlocks1" open={open} toggle={toggle}>
          <AccordionItem>
            <AccordionHeader targetId="1">Case Blocks - {props?.assigned_blocks && props?.assigned_blocks?.count}</AccordionHeader>
            <AccordionBody accordionId="1">
              <div className='allotedclaim-div-data' style={{marginBottom:10}}>
                <div className='allotedclaim-div-data'>
                  <div className='reininvestigator-maindiv2'>
                    <div className='allotedclaim-data '>
                        <div className='assignedblocks-maindiv' style={{ width: '100%', paddingTop:0 }}>
                          <div className='assignedblocks-flexbox'>
                            {props?.assigned_blocks && props?.assigned_blocks?.blocks && props?.assigned_blocks?.blocks.map((item, index) => {
                              return (
                                <div className='allottedblocks-mains opencaseblocks-block-width' key={index}>
                                  <div className='assignedblockimage-position'><img className='assignedblockimage' src={item?.block_status === "completed" ? greentick : item?.block_status === "inProgress" ? orangetick : graytick} /></div>

                                  {(subProductType === "TP" && mandatoryFieldsForTP.includes(item?.block_master_id)) ? (
                                      <div className='assignedblocks-subhead'>{item?.renamed_block_name} <sup style={{fontSize: "10px"}}>*</sup></div>
                                    ) : (subProductType === "OD" && mandatoryFieldsForOD.includes(item?.block_master_id)) ? (
                                      <div className='assignedblocks-subhead'>{item?.renamed_block_name} <sup style={{fontSize: "10px"}}>*</sup></div>
                                    ) : (
                                      <div className='assignedblocks-subhead'>{item?.renamed_block_name} </div>
                                  )}

                                  <div className='assignedblocks-viewbtn-position'>
                                    <button className='assignedblocks-viewbtn' data-testid="CaseBlocks2" onClick={() => { props?.Onviewclick(item?.block_master_id, item?.renamed_block_name, item?.flag_to_add_another) }}><Icon />  View</button>
                                  </div>
                                </div>
                              );
                            })}
                          </div>
                          <div className='assignedblocks-textareamain' style={{padding:5}}>
                            <textarea placeholder='Remarks' value={props?.remark} className='assignedblocks-textarea' data-testid="CaseBlocks4" onChange={(event) => { props?.handleChange(event) }} />
                            <div className='assignedblocks-viewbtn-position2'>
                              <button data-testid="CaseBlocks3" onClick={() => { props?.submitRemark(props?.remark) }} className='assignedblocks-viewbtn' >Submit</button>
                            </div>
                          </div>
                        </div>
                    </div>
                    <button data-testid="CaseBlocks5" className='toggle' onClick={toggle}>
                      <img src={toggleimg}></img>
                    </button>
                  </div>
                </div>
              </div>
            </AccordionBody>
          </AccordionItem>
        </Accordion>
      </div>
    )
})

CaseBlocks.propTypes = {}

export default CaseBlocks;