import React from 'react';
import Rectangle from '../../images/message.gif';
import BackArrow from "../../images/BackArrow.svg";
import { useNavigate } from 'react-router-dom';
import './message.css'

function Message(props) {
  const navigate = useNavigate();
  const backbutton = () => navigate(-1);
  return (
    <div data-testid="MessagePage" className='message'>
        {props.showBackIcon !== false && <img src={BackArrow} alt="back" data-testid="Message-button" onClick={backbutton} className='backbtn'/>}
        <img src={Rectangle} />
        <div className='text-wrapper'>
          <h1 className='message-title'>Coming Soon...</h1>
          <p className='message-desc'>Stay Connected, Stay Updated</p>
        </div>
    </div>
  )
}

export default Message