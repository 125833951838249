import React from 'react';
import './index.css';
import UploadedFileView from '../UploadedFileView';

export function AssignBlocksFormBottom(props) {
    const permitVal = props.docs && props.docs.length > 0 && (props.docs[0].block_parameters_id === 144) && props.docs[0].parameter_value;
    return (
        <div className='AssignBlocksFormBottom'>
            <hr />
            <div className="assignBlock-container">
                <div className="assignBlockdocs-container">
                    {props.headerNotRequired ? null :  permitVal === 'No' ? null : <p className='AssignBlocksFormBottom-title'>Documents <sup>*</sup></p>}
                    {props.newRejectedheader ? <p className='AssignBlocksFormBottom-title'>Uploaded Invoices <sup>*</sup></p> : null}
                    {props.showUploadBtn && (
                        <button className="assignBlock-upload-btn" data-testid="assignBlocksFormBottomfilter" onClick={props.filterToggle}>
                            <img src={require('../../images/uploadIcon.png')} />
                            <span>Upload</span>
                        </button>
                    )}
                </div>
                <div>
                    {props.docs && props.docs.length > 0 && props.docs.map((ele, i) => {
                        if (ele.parameter_type === "upload") {
                            return (
                                <div className="assign-docs-parent2">
                                    <div className='docs-title-btn-wrapper'>
                                        <div className='docs-title-wrapper'>{ele.renamed_block_parameter}</div>
                                        <div className='docs-btn-wrapper'>
                                            <button className="assignBlock-upload-btn2" data-testid="assignBlocksFormBottomfilter2" onClick={() => { props.filterToggle(ele.renamed_block_parameter) }}>
                                                <img src={require('../../images/uploadIcon.png')} />
                                                <span>Upload</span>
                                            </button>
                                        </div>
                                    </div>
                                    {ele.parameter_value && ele.parameter_value.length > 0 && 
                                        <UploadedFileView pageName={props.pageName} deleteOwnFileCheck={props.deleteOwnFileCheck ? props.deleteOwnFileCheck : null} download={props.download} border={props.border} documentList={ele.parameter_value} onClose={(e,y)=>props.onClose(e,y,i)} parentIndex={i} />
                                    }
                                </div>
                            )
                        }
                    })}
                </div>
                <div className="assign-docs-parent" >
                    {props.rejecteddocuments && props.rejecteddocuments.length > 0 &&
                        <UploadedFileView pageName={props.pageName} deleteOwnFileCheck={props.deleteOwnFileCheck ? props.deleteOwnFileCheck : null} download={props.download} border={props.border} documentList={props.rejecteddocuments} onClose={props.showCloseDocs ? props.onClose : null} showCloseDocs={props.showCloseDocs} />
                    }
                </div>
            </div>
            {props.showRemarks && (
                <div className="AssignBlocksFormBottom-textarea">
                    <textarea data-testid="UploadInvoiceFormBottomfilter5"  defaultValue={props.remark} value={props.remark} onChange={(e) => { props.onRemarkChange(e.target.value) }} placeholder='Remarks' />
                </div>
            )}
        </div>
    )
}

export default AssignBlocksFormBottom;