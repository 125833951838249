import React, { useState, useEffect, memo } from 'react';
import onboarding1 from '../../images/onboarding1-bg.png';
import onboarding2 from '../../images/onboarding2-bg.png';
import onboarding3 from '../../images/onboarding3.png';
import onboarding4 from '../../images/onboarding4-bg.png';
import { useNavigate } from "react-router-dom";
import path from '../../constants/pathConstants';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import { getEmployeeRole } from '../App/selectors';
import './Onboarding.css';

export function Onboarding({ empRole }) {
   const [data,setData] = useState([{ text: "Monitor Cases", textWidth: "98px", active: true, image: onboarding1 },
   { text1: "Monitor Your",text2: "Team & Performance", textWidth: "240px", active: false, image: onboarding2 },
   { text: "Stay Updated With Reports", textWidth: "172px", active: false, image: onboarding3 },
   { text: "Check Your Payouts", textWidth: "145px", active: false, image: onboarding4 }])

   const navigate = useNavigate();

    useEffect(() => {
        if (empRole == "investigator") {
            const newData = [...data];
            newData.splice(1, 1);
            setData(newData);
        }
    }, [])

    const onNextClick = () => {
        const currentIndex = data.findIndex(item => item.active);
        if (currentIndex == data.length - 1) {
            redirectToDashboard()
        } else {
            const nextIndex = currentIndex + 1;
            const newData = data.map((item, index) => {
                return { ...item, active: index === nextIndex };
            });
            setData(newData);
        }
    }

    const redirectToDashboard = () => {
        // history.push(path.DASHBOARD)
        navigate(path.DASHBOARD)
    }

    return (
        <div data-testid="onboarding" className='onboarding'>
            <div className='onboarding-header'>
                <img src={require('../../images/Logo2.png')} style={{width:"48px",height:"40px"}} />
                <span className='onboarding-header-title'>CHASE</span>
            </div>
            <div className="onboarding-body">
                {data.find(item => item.active) && (
                    <>
                        {empRole == "vendor" && !data[1].active ? (<span style={{ width: data.find(item => item.active).textWidth }} className="onboarding-title">{data.find(item => item.active).text}</span>):null}
                        {empRole == "investigator" ? (<span style={{ width: data.find(item => item.active).textWidth }} className="onboarding-title">{data.find(item => item.active).text}</span>):null}
                        {empRole == "vendor" && data[1].active ?( <span className="onboarding-title1">{data.find(item => item.active).text1}</span>):null}
                        {empRole == "vendor" && data[1].active ?(  <span className="onboarding-title2">{data.find(item => item.active).text2}</span>):null}
                        <div className="onboarding-container">
                            <img src={data.find(item => item.active).image} />
                        </div>
                    </>
                )}
            </div>
            <div className="onboading-footer">
                <div className="onboading-footer-btns">
                    <button className="onboarding-skip-btn" onClick={redirectToDashboard}>skip</button>
                    <div className="onboarding-carousol-container">
                        {data.map((ele, index) => (
                            <div key={index} className={`${ele.active == true ? 'onboarding-dots-active' : "onboarding-dots"}`}></div>
                        ))}
                    </div>
                    <button data-testid="nextbutton" className="onboarding-next-btn" onClick={onNextClick}>Next</button>
                </div>
                <div className="onboarding-imageCtrlFlex-parent">
                    <div className="onboarding-imageCtrlFlex">
                        <p className="imageCTRLBtn imagectrlNewbtn">Powered by </p>
                        <img src={require('../../images/ctrlLogo.png')} className='imageCTRLBtnStyle'/>
                    </div>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = createStructuredSelector({
    empRole: getEmployeeRole()
});

const withConnect = connect(
    mapStateToProps,
    null,
);

export default compose(withConnect, memo,)(Onboarding);