import React, { memo } from "react";
import "./index.css";
import SearchIcon from "../../images/search.svg";
import MicIcon from "../../images/mic.png";
export function GenericSearch(props) {

  const addSpanToHighlightText = (text, targetText) => {
    var spanStart = '<span className="dark">';
    var spanEnd = '</span>';
    var regex = new RegExp(targetText, "gi");
    var newText = String(text).replace(regex, spanStart + targetText + spanEnd);
    return newText.toUpperCase();
  }

  return (
    <div data-testid="GenericSearchContainer" className={(props.suggestionOpen && props.searchValue && props.searchValue.length > 0) ? "search-box-wrapper open" : "search-box-wrapper"}>
      <div className="search-input-wrapper">
        <img src={SearchIcon} alt="search" className="search-icon" />
        <input data-testid="search-input" type="text" placeholder="Search" className="search-input" value={props.searchValue} onChange={(e) => props.handleInputChange(e)} />
        <div className="mic-wrapper">
          <img src={MicIcon} alt="mic-icon" />
        </div>
      </div>
      {props.suggestionOpen && <div className="suggestion-list-wrapper">
        {props.suggestionList && props.suggestionList.length > 0 && props.suggestionList.map((item, index) => {
          return (
            <div key={index} data-testid="suggestion-list">
              <p className="title">{item.label}</p>
              <ul>
                {item.value && item.value.length > 0 && item.value.map((list) => {
                  const highlightedText = addSpanToHighlightText(list.display, props.searchValue);
                  return (
                    <li data-testid="GenericSearch1" key={list.value} onClick={() => props.handleSuggestionClick(list.column, list.value, list.display)}>
                      <div dangerouslySetInnerHTML={{ __html: highlightedText }} />
                    </li>
                  );
                })}
              </ul>
            </div>
          )
        })}
      </div>}
    </div>
  );
}

GenericSearch.propTypes = {};

export default memo(GenericSearch);