import React from 'react';
import './index.css';

function ComingSoonPopUp({ open, toggle }) {
  return (
    <>
    {open && (
    <div className='coming-soon-popup-parent'>
    <div className='coming-soon-popup'>
    <img onClick={toggle} className='coming-soon-popup-close' src={require('../../images/close2.png')} />
        <div className='text-wrapper'>
          <h1 className='message-title'>Coming Soon...</h1>
          <p className='message-desc'>Stay Connected, Stay Updated</p>
        </div>
    </div>
    </div>
    )}
    </>
  )
}

export default ComingSoonPopUp;