import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Col, Card } from 'reactstrap';
import './investigator.css';
import { connect } from 'react-redux';
import { selectInvestigator } from '../actions';
import { createStructuredSelector } from "reselect";
import {makeSelectAssignInvestigatorId} from "../selectors";
import PCircle from '../../../images/p-circle.svg';
import CustomAvatar from '../../../components/CustomAvatar';

const Investigator = memo(({ employees,selectInvestigator, selectedInvestigator }) => {
  const selectInv = (id) => {
    selectInvestigator(id);
  }
  return (
    <Col xs="4">
      <Card data-testid="btn" className={selectedInvestigator && selectedInvestigator.includes(employees?.vendor_employee_id) ? 'selected-investigator': 'investigator'} onClick={() => selectInv(employees.vendor_employee_id)} >
        {employees.selected && <div className='p-circle'><img src={PCircle} alt="" /></div>}
        <CustomAvatar size="50" round={true} filename={employees?.profileImage ? employees?.profileImage?.profile_image_name : ""} />
        <div className='investigator-name'>{employees?.investigator_name}</div>
        {selectedInvestigator && selectedInvestigator.includes(employees?.vendor_employee_id) ? <div className='selected-lable'>Selected</div>:<></>}
      </Card>
    </Col>
  )
})

Investigator.propTypes = {
  employees: PropTypes.object.isRequired,
  selectInvestigator: PropTypes.func.isRequired,
  selectedInvestigator: PropTypes.func.isRequired
}

const mapStateToProps = createStructuredSelector({
  selectedInvestigator:makeSelectAssignInvestigatorId()
});

const mapDispatchToProps = {
  selectInvestigator
};

export default connect(mapStateToProps, mapDispatchToProps)(Investigator);
