import { useCallback, useContext, useEffect, useState } from "react";
import { Button, ButtonDropdown, DropdownItem, DropdownMenu, DropdownToggle, Input } from "reactstrap";
import { ApiDispatchContext } from "../../../../context/ApiContext";
import ErrorAlert from "../../../../components/ErrorAlert";
import dustbin from "../../../../images/dustbin.svg";
import "./index.css";

const StateAndCity = ({
    onDataChange=() => {},
    stateCity=[],
    index=0,
    currentLength=1,
    removeStates=() => {},
    actionType,
    alreadySelectedStates=[]
}) => {
    const [stateMaster, setStateMaster] = useState([]);
    const [filteredStates, setFilteredStates] = useState([]);
    const [stateSearchTerm, setStateSearchTerm] = useState(stateCity?.state ? stateCity?.state : "")
    const [selectedState, setSelectedState] = useState(stateCity?.state);
    const [cityMaster, setCityMaster] = useState([]);
    const [filteredCities, setFilteredCities] = useState([]);
    const [citySearchTerm, setCitySearchTerm] = useState("")
    const [selectedCities, setSelectedCities] = useState(stateCity?.cities ? stateCity?.cities : []);
    const [isStateOpen, setIsStateOpen] = useState(false);
    const [isCityOpen, setIsCityOpen] = useState(false);
    const [locations, setLocations] = useState([]);
    const api = useContext(ApiDispatchContext);
    const [modal, setModal] = useState(false);
    const [modalTitle, setModalTitle] = useState("");
    const [modalBody, setModalBody] = useState("");
    const toggle = () => setModal(!modal);

    const stateChangeHandler = (state) => {
        setSelectedState(state);
        setStateSearchTerm(state);
        setSelectedCities([]);
        onDataChange("state", state);
    }

    const citiesChangeHandler = (cityName, cityIndex) => {
        const updatedCities = [...selectedCities];
        const copyOfCityMaster = [...cityMaster];
        const position = updatedCities.indexOf(cityName);
    
        if (position !== -1) {
          const changeStatus = {
            "checkStatus": false,
            "cityName": cityName,
            "position": cityIndex
          }
          updatedCities.splice(position, 1);
          copyOfCityMaster.splice(cityIndex, 1, changeStatus);
        } else {
          const changeStatus = {
            "checkStatus": true,
            "cityName": cityName,
            "position": cityIndex
          }
          setCitySearchTerm(cityName);
          updatedCities.push(cityName);
          copyOfCityMaster.splice(cityIndex, 1, changeStatus);
        }
        if(updatedCities?.length === 0) {
        } else if(updatedCities?.length > 1) {
            setCitySearchTerm(`${updatedCities[0]}, +${updatedCities?.length - 1} more`);
        } else {
            setCitySearchTerm(updatedCities[0])
        }
        setCityMaster(copyOfCityMaster);
        setFilteredCities(copyOfCityMaster);
        setSelectedCities(updatedCities);
    }

    const handleApply = () => {
        setIsCityOpen(false);
        const newTempArray = selectedCities?.map(city => ({ 
            'state': selectedState,
            'city': city?.toLowerCase().replace(/\b\w/g, c => c?.toUpperCase())
        }));
        setLocations(newTempArray);
        cityBlur();
        onDataChange("city", { "state": selectedState, "cities": selectedCities } , newTempArray, index);
    }

    const handleError = (errorData) => {
        setModalTitle('Error');
        if (errorData?.error && errorData?.message) {
          setModalBody(errorData?.message);
        } else {
          setModalBody('Please try after sometime');
        }
        toggle(true);
    };

    const getAllStates = useCallback(async () => {
        try {
          const data = await api.getStateMaster();
      
          if (data?.data?.data) {
            const temp = data?.data?.data;
            const filteringStates = temp?.filter(state => !alreadySelectedStates?.includes(state));
            setStateMaster(filteringStates);
            setFilteredStates(filteringStates);
          } else {
            handleError(data?.data);
          }
        } catch (err) {
            handleError(err?.response?.data);
        }
    }, []);

    const getCities = useCallback(async () => {
        if(selectedState?.length > 0) {
            try {
            const payload = {
                state: selectedState,
            };
        
            const data = await api.getCityMaster(payload);
        
            if (data?.data?.data) {
                const tempArray = data?.data?.data?.map((city, i) => ({cityName: city?.city_name, checkStatus: false, position: i, is_present:city?.is_present}));
                tempArray?.forEach(city => {
                    if (selectedCities.includes(city?.cityName)) {
                      city.checkStatus = true;
                    }
                  });
                setCityMaster(tempArray);
                setFilteredCities(tempArray);
            } else {
                handleError(data?.data);
            }
            } catch (err) {
            handleError(err?.response?.data);
            }
        }
    }, [selectedState]);

    const handleStateChange = (value) => {
        setStateSearchTerm(value);
        if(stateSearchTerm) {
            const filteredArray = stateMaster?.filter((data) => data?.toLowerCase().indexOf(value?.toLowerCase()) !== -1)
            setFilteredStates(filteredArray);
        } else{
            setFilteredStates(stateMaster)
        }
    }

    const handleCityChange = (value) => {
        setCitySearchTerm(value);
        if(citySearchTerm) {
            const filteredArray = cityMaster?.filter((data) => data?.cityName?.toLowerCase().indexOf(value?.toLowerCase()) !== -1);
            setFilteredCities(filteredArray);
        } else{
            setFilteredCities(cityMaster);
        }
    }

    const cityBlur = () => {
        if(selectedCities?.length === 0) {
            setCitySearchTerm("");
        } else if(selectedCities?.length > 1) {
            setCitySearchTerm(`${selectedCities[0]}, +${selectedCities?.length - 1} more`);
        } else {
            setCitySearchTerm(selectedCities[0])
        }
    }

    useEffect(() => {
        getAllStates();
    }, [getAllStates]);
    
    useEffect(() => {
      getCities();
    }, [getCities]);

    useEffect(() => {
        actionType === "edit" && handleApply()
    }, []);

  return (
        <div>
        {(index === 0) ? (null) : (
            <div className="removeBtnDiv">
                <button className="removeBtn" onClick={() => removeStates(index, locations)}>
                    <img src={dustbin} alt="delete-state" className="removeBtnIcon" />
                    <span className="removeBtnText" >Remove</span>
                </button>
            </div>
        )}
            <div className='viewemployee-child editemployeemain'>
                <span className='viewemployee-text1 viewempset' >State</span>
                <ButtonDropdown className='cityDropdown' style={{ color: 'black', lineHeight: '35px', width: "50%" }} data-testid="dropdownItem22" isOpen={isStateOpen} toggle={() => setIsStateOpen(!isStateOpen)}>
                <DropdownToggle caret style={{ backgroundColor: 'rgba(152, 82, 144, 0.1)', border: "none", boxShadow: "none", color: "#3A3A3A", fontWeight: "500", fontSize: "10px", lineHeight: "15px", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis", borderRadius: "5px" }}>
                        <Input
                            type="text"
                            value={stateSearchTerm}
                            data-testid="LocationDropdown1"
                            onChange={(e) => handleStateChange(e.target.value)}
                            className="location-input"
                            style={{ backgroundColor: 'rgba(152, 82, 144, 0.01)', border: 'transparent', padding: 0, fontSize: 12 }}
                        />
                    </DropdownToggle>
                    <DropdownMenu style={{ backgroundColor: '#F3ECF4'}}>
                        <div style={{ maxHeight: "250px", overflowY: "scroll", overflowX: "hidden" }}>
                            {filteredStates?.length > 0 && filteredStates?.map((s, stateIndex) => (
                                    <DropdownItem onClick={() => stateChangeHandler(s)} style={{padding:5}} data-testid="dropdownItem" key={`${s}-${stateIndex}`}  className="form-control">{s}</DropdownItem>
                            ))}
                        </div>
                    </DropdownMenu>
                </ButtonDropdown>
            </div>

            <div className='viewemployee-child editemployeemain'>
                <span className='viewemployee-text1 viewempset' >City</span>
                <ButtonDropdown className='cityDropdown' style={{ color: 'black', lineHeight: '35px', width: "50%" }} data-testid="dropdownItem22" isOpen={isCityOpen} toggle={() => selectedState?.length > 0 && setIsCityOpen(!isCityOpen)}>
                    <DropdownToggle caret style={{ backgroundColor: 'rgba(152, 82, 144, 0.1)', border: "none", boxShadow: "none", color: "#3A3A3A", fontWeight: "500", fontSize: "10px", lineHeight: "15px", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis", borderRadius: "5px" }}>
                            <Input
                                value={citySearchTerm}
                                type="text"
                                data-testid="LocationDropdown2"
                                className="location-input"
                                onChange={(e) => handleCityChange(e.target.value)}
                                onFocus={() =>  setCitySearchTerm("")}
                                onBlur={cityBlur}
                                style={{ backgroundColor: 'rgba(152, 82, 144, 0.01)', border: 'transparent', padding: 0, fontSize: 12 }}
                            />
                        </DropdownToggle>
                    <DropdownMenu style={{ backgroundColor: '#F3ECF4'}}>
                        <div style={{ maxHeight: "250px", overflowY: "scroll", overflowX: "hidden" }}>
                            {filteredCities?.map((c, cityIndex) => (
                                <div key={`${c.cityName}-${cityIndex}`} style={{ display: "flex", alignItems: "center", marginBottom: "5px" }}>
                                    <Input type="checkbox" checked={c.checkStatus} style={{margin: "0 10px"}} onChange={(e) => citiesChangeHandler(c.cityName, c.position)}/>
                                    <label style={{color:'#000', fontWeight:'500'}} className={"cityInputField"}>{c.cityName}</label>
                                </div>
                            ))}
                        </div>
                        <div style={{ float: "right", marginRight: "30px" }}>
                            <Button color="primary" onClick={() => handleApply()}>
                                Apply
                            </Button>
                        </div>
                    </DropdownMenu>
                </ButtonDropdown>
            </div>

            {(currentLength-1) === index ? (null) : (
                <div
                    className="viewemployee-child editemployeemain" 
                    style={{margin: "4px 0px 8px", borderBottom: "0.5px solid rgb(180, 180, 180)", height: "5px"}}
                >
                    <span className="viewemployee-text1 viewempset"></span>
                    <label className="viewemployee-text2 viewempset">
                        <button className="addMoreStatesBtnActive addMoreStatesBtnNotactive" style={{border: "none", background: "none"}}></button>
                    </label>
                </div>
            )}

            <ErrorAlert
                modal={modal}
                toggle={toggle}
                modalTitle={modalTitle}
                modalBody={modalBody}
            />
        </div>
  )
}

export default StateAndCity