import React, { memo, useEffect, useState, useContext } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { compose } from "redux";
import { useInjectReducer } from "../../utils/injectReducer";
import makeSelectCaseDetailspage from "./selectors";
import reducer from "./reducer";
import Case from "../../components/case";
import { ApiDispatchContext } from "../../context/ApiContext";
import { caseCountApiCall } from "../../constants/commonFunction";
import { dummyAllottedCases } from "../../constants/cardConstants";
import { casesUpdate } from "./actions";
import FilterPopUp from "../../components/FilterPopUp";
import FilterActions from '../../components/FilterActions';
import { makeSelectCardCasesPage } from "./selectors";
import path from "../../constants/pathConstants";
import ReactPaginate from "../../components/ReactPaginate/index";
import { getEmployeeRole } from "../App/selectors";
import CommonNavbar from "../../components/CommonNavbar";
import CaseCard from "./CaseCard";
import './index.css'
import { handleExportClick } from "../../constants/commonFunction";
import { useNavigate } from 'react-router-dom';
import ErrorAlert from "../../components/ErrorAlert";
export function AllottedCases({ employeeRole, getCases, updateCase }) {
  useInjectReducer({ key: "allottedCasesPage", reducer });
  const api = useContext(ApiDispatchContext);
  const [casesCards, setCasesCards] = useState([])
  const [modal, setModal] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [modalBody, setModalBody] = useState("");
  const [currentPage, setCurrentPage] = useState(0);
  const [totalrecords, settotalrecords] = useState(0);
  const [pageCount, setpageCount] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [open, setOpen] = useState(false);
  const toggle = () => setModal(!modal);
  const [filterflag , setfilterflag] = useState(false);
  const [filterform, setfilterform] = useState({})
  const [payloadExcelDownload,setpayloadExcelDownload] = useState({})
  const [filterHeight, setfilterHeight] = useState('auto');
  
  const navigate = useNavigate();

  useEffect(() => {
    api.casesCount().then(result => {
      if(result.data && result.data.success){
      const tempCases = caseCountApiCall(employeeRole, dummyAllottedCases, result)
      setCasesCards(tempCases)
      }else{
        setModalTitle("Error");
        if(result.data.error && result.data.message){
          setModalBody(result.data.message)
        }
        else{
          setModalBody('Please try after sometime');
        }
        toggle();
      }
    }).catch(err => {
      setModalTitle("Error");
      if(err.response && err.response.data && err.response.data.message){
        setModalBody(err.response.data.message);
      } else {
        setModalBody('Please try after sometime');
      }
      toggle();
    });
    let param = {
      "caseType": "alloted",
      "page": 1,
      "pageSize": 10
    }
    api.getCasesDetails(param).then(data=>{
      if(data.data && data.data.success){
        updateCase(data.data.data.rows);
        settotalrecords(data.data.data.count);
        const pageSize = Math.ceil(data.data.data.count / 10);
        setpageCount(pageSize)
      } else {
        setModalTitle("Error");
        if(data.data.error && data.data.message){
          setModalBody(data.data.message)
        } else{
          setModalBody('Please try after sometime');
        }
        toggle();
      }
    }).catch(err=>{
      setModalTitle("Error");
      if (err && err.response && err.response.data && err.response.data.message) {
        setModalBody(err.response.data.message);
      } else {
        setModalBody('Please try after sometime.');
      }
      toggle();
    });
  }, [])

  const renderItems = () => { //render items casecard
    let tempdata = [];
    for (var i = 0; i < getCases.length; i++) { 
      if (i>=0 && i < 10) {
        tempdata[i] = getCases[i];
      } 
    }
    return <CaseCard selectCards={tempdata} />
  };

  const handlePageChange = ({ selected }) => { //on changes of page update current page
    let param = {
      "caseType": "alloted",
      "page": selected+ 1,
      "pageSize": 10
    }
    api.getCasesDetails(param).then(data=>{
      if(data.data && data.data.success){
        updateCase(data.data.data.rows);
        settotalrecords(data.data.data.count);
        const pageSize = Math.ceil(data.data.data.count / 10);
        setpageCount(pageSize)
        renderItems()
      } else {
        setModalTitle("Error");
        if(data.data.error && data.data.message){
          setModalBody(data.data.message)
        } else{
          setModalBody('Please try after sometime');
        }
        toggle();
      }
    }).catch(err=>{
      setModalTitle("Error");
      if (err && err.response && err.response.data && err.response.data.message) {
        setModalBody(err.response.data.message);
      } else {
        setModalBody('Please try after sometime.');
      }
      toggle();
    });
    setCurrentPage(selected);
  };

  const onCasesClick = (path) => {
    navigate(path)
  }

  const filterpopupShow =() =>{
    setOpen(true)
  }

  const filterToggle = () => {
    setOpen(!open);
  }

  const submitFilterform = (data) =>{
    setfilterform(data);
    const payload = {
      caseType: "alloted",
      filter: {
        customersegmenttype:{}
      }
    };
    data.forEach(item => {
      if (item.label === 'Date') {
        if (item.selectedvalue1) {
          const date = new Date(item.selectedvalue1);
          const year = date.getFullYear();
          const month = String(date.getMonth() + 1).padStart(2, "0");
          const day = String(date.getDate()).padStart(2, "0");
          const formattedDate = `${year}-${month}-${day}`;
          payload.filter.fromDate = formattedDate;
        } else {
          payload.filter.fromDate = "";
        }
        if (item.selectedvalue2) {
          const date = new Date(item.selectedvalue2);
          const year = date.getFullYear();
          const month = String(date.getMonth() + 1).padStart(2, "0");
          const day = String(date.getDate()).padStart(2, "0");
          const formattedDate = `${year}-${month}-${day}`;
          payload.filter.toDate = formattedDate;
        } else {
          payload.filter.toDate = "";
        }
      } else {
        const key = item.label.toLowerCase().replace(/[^a-zA-Z0-9]/g, '');
        const dataArray = item.value;
        for (let i = 0; i < dataArray.length; i++) {
          if (dataArray[i].value === item.selectedvalue1) {
            payload.filter[key] = {
              id: dataArray[i].id,
              column_name: dataArray[i].column_name,
              value: item.selectedvalue1
            };
            break; // Stop the loop after the first match
          } else {
            payload.filter[key] = {}
          }
        }
      }
    });
    setpayloadExcelDownload(payload);
    api.getCasesDetails(payload).then(data=>{
      if(data.data && data.data.success){
        updateCase(data.data.data.rows);
        setfilterflag(true);
      } else {
        setModalTitle("Error");
        if(data.data.error && data.data.message){
          setModalBody(data.data.message)
        } else{
          setModalBody('Please try after sometime');
        }
        toggle();
      }
    }).catch(err=>{
      setModalTitle("Error");
      if (err && err.response && err.response.data && err.response.data.message) {
        setModalBody(err.response.data.message);
      } else {
        setModalBody('Please try after sometime.');
      }
      toggle();
    });
    setOpen(!open);
  }

  const renderItemssearch = () =>{
    let tempdata = [];
    for (var i = 0; i < getCases.length; i++) { 
        tempdata[i] = getCases[i];
    }
    return <CaseCard selectCards={tempdata} />
  }

  const downloadReport = () => {
    let param = {
      "caseType": "alloted"
    }
    if(filterflag){
      param = payloadExcelDownload;
    }
    api.getReportExcel(param).then(data => {
      if (data.data.success) {
        handleExportClick(data.data.data, "Alloted_Report", "Alloted_Report")
      } else {
        setModalTitle("Error");
        if (data.data.error && data.data.message) {
          setModalBody(data.data.message)
        }
        else {
          setModalBody('Please try after sometime');
        }
        toggle();
      }
    }).catch(err => {
      setModalTitle("Error");
      if (err && err.response && err.response.data && err.response.data.message) {
        setModalBody(err.response.data.message);
      } else {
        setModalBody('Please try after sometime.');
      }
      toggle();
    });
  }

  const datepickerClicked = (value) => {
    if (value === false) {
      setfilterHeight('auto')
    } else
      setfilterHeight('450px')
  } 

  const resetFilter = () => {
    setfilterform({});
    let param = {
      "caseType": "alloted",
      "page": 1,
      "pageSize": 10
    }
    api.getCasesDetails(param).then(data=>{
      if(data.data && data.data.success){
        setfilterflag(false);
        updateCase(data.data.data.rows);
        settotalrecords(data.data.data.count);
        const pageSize = Math.ceil(data.data.data.count / 10);
        setpageCount(pageSize)
      } else {
        setModalTitle("Error");
        if(data.data.error && data.data.message){
          setModalBody(data.data.message)
        } else{
          setModalBody('Please try after sometime');
        }
        toggle();
      }
    }).catch(err=>{
      setModalTitle("Error");
      if (err && err.response && err.response.data && err.response.data.message) {
        setModalBody(err.response.data.message);
      } else {
        setModalBody('Please try after sometime.');
      }
      toggle();
    });
  }

  return (
    <div className="Alloted-case-page">
      <CommonNavbar title="Allotted Cases" backButton={path.DASHBOARD} search={false} />
      <div className="alloted-button-cards">
       {Array.isArray(casesCards) &&
          casesCards.map((ele, index) => (
            <Case key={index} employeeRole={employeeRole} caseNumber={ele?.caseNumber} cases={ele?.cases} color={ele?.color} bgColor={ele?.bgColor} onClick={() => onCasesClick(ele?.path)} boxShadow={ele?.boxShadow} />
          ))
        }
      </div>
      <div>
      {open && (<FilterPopUp open={open} backgroundColor={'#8F949B80'} filterToggle={filterToggle} height={filterHeight ? filterHeight : 'auto'} >
        <FilterActions caseType="alloted" onResetFilter={resetFilter} submitFilterform ={(data) => submitFilterform(data)}  filterform={filterform} datepickerClicked={(value) =>datepickerClicked(value)}/>
      </FilterPopUp>)}
      {getCases &&
          <ReactPaginate
            data-testid="reactPaginate"
            filterpopupShow={filterpopupShow}
            getCases={getCases}
            currentPage={currentPage}
            pageCount={pageCount}
            filterflag={filterflag}
            itemsPerPage={itemsPerPage}
            renderItems={filterflag ? renderItemssearch() : renderItems()}
            handlePageChange={(value) => handlePageChange(value)}
            totalrecords={totalrecords}
            caseType="alloted"
            downloadReport={downloadReport}
          />
        }
      </div>
      <ErrorAlert
        modal={modal}
        toggle={toggle}
        modalTitle={modalTitle}
        modalBody={modalBody}
      />
    </div>
  );
}

AllottedCases.propTypes = {
  dispatch: PropTypes.func
};

const mapStateToProps = createStructuredSelector({
  employeeRole: getEmployeeRole(),
  getCases: makeSelectCardCasesPage(),
  caseDetailspage: makeSelectCaseDetailspage()
});

export function mapDispatchToProps(dispatch) {
  return {
    updateCase: (data) => dispatch(casesUpdate(data))
  };
}

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps
);

export default compose(withConnect, memo)(AllottedCases);