import React, { memo, useContext, useEffect, useState } from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { compose } from "redux";
import { useInjectReducer } from "../../utils/injectReducer";
import makeSelectOpenCasesDetailsPage from "./selectors";
import reducer from "./reducer";
import RejectedInvoiceCard from "./CaseCard";
import { makeSelectCardCases } from "../OpenCases/selectors";
import { getUrlParameter } from "../../constants/commonFunction";
import { ApiDispatchContext } from "../../context/ApiContext";
import { casesUpdate } from "../OpenCases/actions";
import ErrorAlert from "../../components/ErrorAlert";
import CommonNavbar from "../../components/CommonNavbar/index";
import path from "../../constants/pathConstants";
 import AssignBlocksFormBottom from "../../components/AssignBlocksFormBottom";
import './index.css';
import PropTypes from "prop-types";
import { push } from 'connected-react-router';
import { getEmployeeRole } from '../App/selectors';
import AcceptButtons from "../OpenCasesDetailsPage/AcceptButtons";
import SuccessMessage from "../../components/SuccessMssgDisplay";
import edit1 from '../../images/edit1.png'
import { getVendorEmployeeID } from "../App/selectors";
import { useNavigate } from 'react-router-dom';

export function RejectedInvoicesDetailsPage({ allCases, updateCase , employeeRole, redirectPath, employeeId}) {
  useInjectReducer({ key: "RejectedInvoicesDetailsPage", reducer });
  const [selectedValue, setSelectedValue] = useState();
  const api = useContext(ApiDispatchContext);
  const [modal, setModal] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [modalBody, setModalBody] = useState("");
  const [disabledSubmitBtn, setDisabledSubmitBtn] = useState(true);
  const [invoiceList, setInvoiceList] = useState([]);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [showCloseDocs,setShowCloseDocs] = useState(false)
  const toggle = () => setModal(!modal);
  let caseid = Number(getUrlParameter('case'));

  const navigate = useNavigate();

  useEffect(() => {
    if (allCases) {
      selectedCard(allCases);
    } else {
      let param = {
        "caseType": "rejectedInvoice",
      }
      api.getCasesDetails(param).then(data => {
        if (data.data && data.data.success) {
          updateCase(data.data.data.rows)
          selectedCard(data.data.data.rows)
        } else {
          setModalTitle("Error");
          if(data.data.error && data.data.message){
            setModalBody(data.data.message)
          }
          else{
            setModalBody('Please try after sometime');
          }
          toggle();
        }
      }).catch(err => {
        setModalTitle("Error");
        if(err && err.response && err.response.data && err.response.data.message){
          setModalBody(err.response.data.message);
        } else {
          setModalBody('Please try after sometime.');
        }
        toggle();
      })
    }
    api.getInvoiceList({ "caseId": caseid, "document_type":"rejectedInvoice" }).then(data => {
      if (data.data.success) {
        setInvoiceList(data.data.data);
        if(data.data.data.newInvoices && data.data.data.newInvoices.length > 0) {
          setDisabledSubmitBtn(false);
        }
      } else {
        setModalTitle("Error");
        if(data.data.error && data.data.message){
          setModalBody(data.data.message)
        }
        else{
          setModalBody('Please try after sometime');
        }
        toggle();
      }
    }).catch(err => {
      setModalTitle("Error");
      if(err && err.response && err.response.data && err.response.data.message){
        setModalBody(err.response.data.message);
      } else {
        setModalBody('Please try after sometime.');
      }
      toggle();
    })
  }, []);

  const selectedCard = (cards) => {
    cards.forEach(element => {
      if (element[1].value === Number(getUrlParameter('case'))) { 
        setSelectedValue([element]);
      }
    });
  }

  const checkEditenable = () => {
    for (let index = 0; index < invoiceList.newInvoices.length; index++) {
      if(invoiceList.newInvoices[index].vendor_employee_id === employeeId){
        return true;
      }
    }
    return false;
  }

  const onAcceptBtnClick = () => {
    navigate(path.ADD_NEW_INVOICE + '?'+ btoa('case=' + caseid));
  }
  
  const onSubmitBtnClick = () => {
    if(employeeRole === "vendor") {
      api.rejectedInvoiceSubmit({ "caseId": caseid }).then(data => {
        if (data.data.success) {
          navigate(
            path.SUCCESS_PAGE,
            {
              state: {stateVal: path.REJECTED_INVOICES,
              key: btoa("Invoice submitted successfully to Investigating Manager")}
            })
        } else {
          setModalTitle("Error");
          if(data.data.error && data.data.message){
            setModalBody(data.data.message)
          }
          else{
            setModalBody('Please try after sometime');
          }
          toggle();
        }
      }).catch(err => {
        setModalTitle("Error");
        if(err && err.response && err.response.data && err.response.data.message){
          setModalBody(err.response.data.message);
        } else {
          setModalBody('Please try after sometime.');
        }
        toggle();
      })
    } else {
      api.rejectedInvoiceSubmitToVendor({ "caseId": caseid }).then(data => {
        if (data.data.success) {
          navigate(
              path.SUCCESS_PAGE,
              {state: {stateVal: path.REJECTED_INVOICES,
              text: btoa("Invoice submitted successfully to Vendor")}
              }
            )
        } else {
          setModalTitle("Error");
          if(data.data.error && data.data.message){
            setModalBody(data.data.message)
          }
          else{
            setModalBody('Please try after sometime');
          }
          toggle();
        }
      }).catch(err => {
        setModalTitle("Error");
        if(err && err.response && err.response.data && err.response.data.message){
          setModalBody(err.response.data.message);
        } else {
          setModalBody('Please try after sometime.');
        }
        toggle();
      })
    }
  }

  const onClose = (e,index) => {
    e.stopPropagation();
    const list =  [...invoiceList.newInvoices];
    let invoice_documents_id = list[index]["invoice_documents_id"];
    api.deleteNewInvoiceList({ "caseId": caseid, "invoice_documents_id": invoice_documents_id, "document_type": "rejectedInvoice" }).then(data => {
      if (data.data.success) {
        list.splice(index,1);
        setInvoiceList({...invoiceList,newInvoices:list});
        if(list.length <= 0) {
          setDisabledSubmitBtn(true);
        }
      } else {
        setModalTitle("Error");
        if(data.data.error && data.data.message){
          setModalBody(data.data.message)
        }
        else{
          setModalBody('Please try after sometime');
        }
        toggle();
      }
    }).catch(err => {
      setModalTitle("Error");
      if(err && err.response && err.response.data && err.response.data.message){
        setModalBody(err.response.data.message);
      } else {
        setModalBody('Please try after sometime.');
      }
      toggle();
    })
  } 
  return (
    <div className="rejected-cases-details-page">
      <CommonNavbar title="Rejected Invoices" search={false} download={false}  backButton={path.REJECTED_INVOICES} navbarname='RejectedInvoicesDetails' />
      <div className="rejectedcasesDetails-main">
        <RejectedInvoiceCard selectCards={selectedValue} />
        <div className='investgation-header'>Previous Invoices</div>
        {invoiceList.previousInvoices && invoiceList.previousInvoices.length > 0 && 
          <AssignBlocksFormBottom download={false} headerNotRequired={true} boxshadow={'0px 2px 4px rgba(0, 0, 0, 0.25)'} rejecteddocuments={invoiceList.previousInvoices} border=" 1px solid #7C144B" showUploadBtn={false} showRemarks={false} />
        }
        {invoiceList.newInvoices && invoiceList.newInvoices.length > 0 && <>
          <div style={{display:"flex",justifyContent:"space-between",alignItems:"center"}}>
            <div className='investgation-header'>New Invoices</div>
            {checkEditenable() &&
              <img style={{height:"17px",width:"17px",marginTop:"10px",marginRight:"13px"}} data-testid="RejectedInvoicesDetailsPage1" src={edit1} onClick={()=>setShowCloseDocs(!showCloseDocs)} />
            }
            </div>  
          <AssignBlocksFormBottom download={false} showCloseDocs={showCloseDocs} onClose={(e,index)=>onClose(e,index)} headerNotRequired ={true} boxshadow={'0px 2px 4px rgba(0, 0, 0, 0.25)'} rejecteddocuments={invoiceList.newInvoices} border=" 1px solid #7C144B" showUploadBtn={false} showRemarks={false} />
        </>}
      </div>
      <div className="AcceptButtons-ContainerParent">
        <div className="AcceptButtons-Container">
          <AcceptButtons background="#E94D51" color = '#fff' datatestid="RejectedInvoicesDetailsPage2" onClick={() => onAcceptBtnClick()}>Add New Invoice</AcceptButtons>
          <AcceptButtons disabled={disabledSubmitBtn} background={disabledSubmitBtn ? "rgba(89, 206, 143, 0.5)" : "#59CE8F"} color = '#fff' datatestid="RejectedInvoicesDetailsPageSubmit" onClick={() => onSubmitBtnClick()}>Submit</AcceptButtons>
        </div>
      </div>
      <ErrorAlert
        modal={modal}
        toggle={toggle}
        modalTitle={modalTitle}
        modalBody={modalBody}
      />
      {showSuccessMessage && (
        <SuccessMessage message="Records Updated Successfully!" delay={30000} />
      )}
    </div>
  );
}

RejectedInvoicesDetailsPage.propTypes = {
  dispatch: PropTypes.func.isRequired,
  employeeRole: PropTypes.string,
};

const mapStateToProps = createStructuredSelector({
  openCasesDetailsPage: makeSelectOpenCasesDetailsPage(),
  allCases: makeSelectCardCases(),
  employeeRole: getEmployeeRole(),
  employeeId: getVendorEmployeeID()
});

export function mapDispatchToProps(dispatch) {
  return {
    updateCase: (data) => dispatch(casesUpdate(data)),
    redirectPath: (path) => dispatch(push(path)),
  };
}

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps
);

export default compose( withConnect, memo)(RejectedInvoicesDetailsPage);