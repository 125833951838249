import React, {  memo, useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { compose } from "redux";
import { useInjectReducer } from "../../utils/injectReducer";
import makeSelectUploadInvoiceDetails from "./selectors";
import reducer from "./reducer";
import CommonNavbar from "../../components/CommonNavbar";
import { ApiDispatchContext } from "../../context/ApiContext";
import { getUrlParameter } from "../../constants/commonFunction";
import AcceptButtons from "../OpenCasesDetailsPage/AcceptButtons";
import SuccessMessage from "../../components/SuccessMssgDisplay";
import ErrorAlert from "../../components/ErrorAlert";
import edit1 from '../../images/edit1.png'
import path from "../../constants/pathConstants";
import AssignBlocksFormBottom from "../../components/AssignBlocksFormBottom";
import Card from './CaseCard';
import { push } from 'connected-react-router';
import { getVendorEmployeeID } from "../App/selectors";
import { useNavigate } from 'react-router-dom';

export function UploadInvoiceDetails({employeeId,deleteOwnFileCheck=true}) {
  useInjectReducer({ key: "uploadInvoiceDetails", reducer });
  const [selectedValue, setSelectedValue] = useState();
  const [modal, setModal] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [modalBody, setModalBody] = useState("");
  const [invoiceList, setInvoiceList] = useState([]);
  const [invoiceIds, setInvoiceIds] = useState([]);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [showCloseDocs,setShowCloseDocs] = useState(false)
  const [previousInvoicesIds, setPreviousInvoicesIds] = useState([]);
  const toggle = () => setModal(!modal);
  const api = useContext(ApiDispatchContext);
  let caseid = Number(getUrlParameter('case'));
  let casetype = getUrlParameter('casetype');
  let subProduct = getUrlParameter('subProduct');

  const navigate = useNavigate();

  useEffect(() => {
    let param = {
      "caseType": type(),
    }
    api.getCasesDetails(param).then(data => {
      if (data.data && data.data.success && data.data.data.rows.length) {
        selectedCard(data.data.data.rows)
      } else {
        setModalTitle("Error");
        if (data.data.error && data.data.message) {
          setModalBody(data.data.message)
        }
        else {
          setModalBody('Please try after sometime');
        }
        toggle();
      }
    }).catch(err => {
      setModalTitle("Error");
      if (err && err.response && err.response.data && err.response.data.message) {
        setModalBody(err.response.data.message);
      } else {
        setModalBody('Please try after sometime.');
      }
      toggle();
    })
    api.getInvoiceList({ "caseId": caseid, "document_type": "actionInvoice" }).then(data => {
      if (data.data.success) {
        setInvoiceList(data.data.data);
        if(data.data.data.previousInvoices && data.data.data.previousInvoices.length > 0) {
          const invoiceMasterId = data.data.data.previousInvoices.map(invoice => invoice.invoice_master_id)
          setPreviousInvoicesIds(invoiceMasterId)
        }
      } else {
        setModalTitle("Error");
        if (data.data.error && data.data.message) {
          setModalBody(data.data.message)
        }
        else {
          setModalBody('Please try after sometime');
        }
        toggle();
      }
    }).catch(err => {
      setModalTitle("Error");
      if (err && err.response && err.response.data && err.response.data.message) {
        setModalBody(err.response.data.message);
      } else {
        setModalBody('Please try after sometime.');
      }
      toggle();
    })
    api.getAddedInvoiceIds({ "caseId": caseid, "document_type": "actionInvoice" }).then(data => {
      if (data.data.success) {
        setInvoiceIds(data.data.data);
      } else {
        setModalTitle("Error");
        if (data.data.error && data.data.message) {
          setModalBody(data.data.message)
        }
        else {
          setModalBody('Please try after sometime');
        }
        toggle();
      }
    }).catch(err => {
      setModalTitle("Error");
      if (err && err.response && err.response.data && err.response.data.message) {
        setModalBody(err.response.data.message);
      } else {
        setModalBody('Please try after sometime.');
      }
      toggle();
    })
  }, []);

  const selectedCard = (cards) => {
    cards.forEach(element => {
      if (element[1].value === Number(getUrlParameter('case'))) { 
        setSelectedValue([element]);
      }
    });
  }

  const onAcceptBtnClick = () => {
    navigate(path.UPLOAD_INVOICE +'?'+btoa('case='+ caseid+ '&&casetype=' + casetype + '&subProduct=' + subProduct))
  }
  
  const onClose = (e,index) => {
    e.stopPropagation();
    const uploadedInvoiceList = [...invoiceIds.invoiceIds];
    api.deleteinvoice({
      "invoiceId": uploadedInvoiceList[index],
      "caseId": caseid
    }).then(data => {
        if (data.data.success) {
          uploadedInvoiceList.splice(index,1);
          setShowSuccessMessage(true)
          setInvoiceIds({"invoiceIds":uploadedInvoiceList})
        } else {
          setModalTitle("Error");
          if(data.data.error && data.data.message){
            setModalBody(data.data.message)
          }
          else{
            setModalBody('Please try after sometime');
          }
          toggle();
        }
      }).catch(err => {
        setModalTitle("Error");
        if(err && err.response && err.response.data && err.response.data.message){
          setModalBody(err.response.data.message);
        } else {
          setModalBody('Please try after sometime.');
        }
        toggle();
      })
  } 

  function borderColor() {
    return casetype === "opencase" ? " 1px solid #1AB275" : casetype === "allottedcase" ? "1px solid #058AEE" : "1px solid #EBAF00";
  }

  function type(){
    return casetype === "opencase" ? "open" : casetype === "allottedcase" ? "alloted" : "reinvestigated";
  }
  
  function cardClass(){
    return casetype === "opencase" ? "open-caseCard" : casetype === "allottedcase" ? "alloted-caseCard" : "rein-caseCard";
  }
  
  function redirectionPath() {
    if (casetype === "opencase") {
      return path.OPEN_CASE_DETAILS + '?'+ btoa('case=' + caseid + '&subProduct=' + subProduct);
    } else if (casetype === "allottedcase") {
      return path.ALLOTED_CASE_DETAILS + '?'+ btoa('case=' + caseid + '&subProduct=' + subProduct);
    } else {
      return path.REINVESTIGATE_CASES_DETAILS + '?'+ btoa('case=' + caseid + '&subProduct=' + subProduct);
    }
  }

  return (
  <div className="rejected-cases-details-page">
    <CommonNavbar title="Uploaded Invoices" search={false} navbarname={casetype==="opencase"?'AssignedBlocksOpenCases':casetype==="reinvestigatecases"?"AssignedBlocksReinCases":"AssignedBlocksAllottedCases"} backButton={redirectionPath()} />
    <div style={{marginTop:"20px"}} className="">
        <Card cardClass={cardClass()} casetype={casetype} selectCards={selectedValue} />
        {invoiceList && invoiceList.previousInvoices && invoiceList.previousInvoices.length > 0 && <>
          <div style={{display:"flex",justifyContent:"space-between",alignItems:"center"}}>
            <div className='investgation-header'>Previous Invoices</div>
          </div>
          <AssignBlocksFormBottom pageName={"UploadInvoiceDetails"} deleteOwnFileCheck={deleteOwnFileCheck} download={false} showCloseDocs={showCloseDocs} headerNotRequired ={true} boxshadow={'0px 2px 4px rgba(0, 0, 0, 0.25)'} rejecteddocuments={previousInvoicesIds} border={borderColor()} showUploadBtn={false} showRemarks={false} />
        </>}
        {invoiceIds && invoiceIds.invoiceIds && invoiceIds.invoiceIds.length > 0 && <>
          <div style={{display:"flex",justifyContent:"space-between",alignItems:"center"}}>
            <div className='investgation-header'>New Invoices</div>
             <img style={{height:"17px",width:"17px",marginTop:"10px",marginRight:"13px"}} data-testid="UploadInvoiceDetails2" src={edit1} onClick={()=>setShowCloseDocs(!showCloseDocs)} />
            </div>  
            <AssignBlocksFormBottom pageName={"UploadInvoiceDetails"} deleteOwnFileCheck={deleteOwnFileCheck} download={false} showCloseDocs={showCloseDocs} onClose={(e,index)=>onClose(e,index)} headerNotRequired ={true} boxshadow={'0px 2px 4px rgba(0, 0, 0, 0.25)'} rejecteddocuments={invoiceIds.invoiceIds} border={borderColor()} showUploadBtn={false} showRemarks={false} />
        </>}
      </div>
      <div className="AcceptButtons-ContainerParent">
        <div className="AcceptButtons-Container">
          <AcceptButtons background="#E94D51" color = '#fff' datatestid="save-button" onClick={() => onAcceptBtnClick()}>Add New Invoice</AcceptButtons>
        </div>
      </div>
      <ErrorAlert
        modal={modal}
        toggle={toggle}
        modalTitle={modalTitle}
        modalBody={modalBody}
      />
      {showSuccessMessage && (
        <SuccessMessage message="Records Deleted Successfully!" delay={3000} />
      )}
  </div>
  );
}

UploadInvoiceDetails.propTypes = {
  dispatch: PropTypes.func
};

const mapStateToProps = createStructuredSelector({
  uploadInvoiceDetails: makeSelectUploadInvoiceDetails(),
  employeeId: getVendorEmployeeID()
});

export function mapDispatchToProps(dispatch) {
  return {
    redirectPath: (path) => dispatch(push(path)),
  };
}

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps
);

export default compose( withConnect, memo)(UploadInvoiceDetails);