import React from 'react';
import { FormattedMessage } from 'react-intl';
import A from './A';
import Img from './Img';
import NavBar from './NavBar';
import HeaderLink from './HeaderLink';
import messages from './messages';

function Header() {
  return (
    <div>
      <A href="https://www.reactboilerplate.com/">
      </A>
      <NavBar>
      <HeaderLink to="/" className="HeaderLink">
        <img src={require('../../images/Logo2.png')} alt="imgage" className="imageLogoMain"/>
        </HeaderLink>
      </NavBar>
    </div>
  );
}

export default Header;
