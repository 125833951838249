import React, { memo, useState } from 'react';
import { Accordion, AccordionBody, AccordionHeader, AccordionItem } from 'reactstrap';
  import "../cardtab.css";
  import toggleimg from "../../../../images/allotted_not_collapsed.png";

const AssignedInvestigators = memo((props) => {
    const [open, setOpen] = useState('0');
    const toggle = (id) => {
      if (open === id) {
        setOpen('0');
      } else {
        setOpen(id);
      }
    };
    return (
        <div className='assignedInvestigators'>
            <Accordion open={open} toggle={toggle}>
                <AccordionItem>
                    <AccordionHeader targetId="1">Assigned Investigators - {props?.assigned_investigators && props?.assigned_investigators?.count}</AccordionHeader>
                    <AccordionBody accordionId="1">
                        {props?.assigned_investigators && props?.assigned_investigators?.investigators && props?.assigned_investigators?.investigators.map((item,index) => {
                            return (
                                <div className='row invList' key={index}>
                                    {item.map((data, innerIndx) => {
                                        return (
                                            <div className='col-4' key={innerIndx}>
                                                <div className='label'>{data?.label} :</div>
                                                <div className='value' title={data?.value}>{data?.value}</div>
                                            </div>
                                        )
                                    })}
                                </div>
                            )
                        })}
                        <div className='toggle' onClick={toggle}>
                            <img src={toggleimg}></img>
                        </div>
                    </AccordionBody>
                </AccordionItem>
            </Accordion>
        </div>
    )
})

AssignedInvestigators.propTypes = {}

export default AssignedInvestigators