import React, { memo, useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { compose } from "redux";
import { useInjectReducer } from "../../utils/injectReducer";
import makeSelectUploadInvoice, {setvaluefetched} from "./selectors";
import reducer from "./reducer";
import CommonNavbar from "../../components/CommonNavbar";
import path from "../../constants/pathConstants";
import { push } from 'connected-react-router';
import { getUrlParameter } from "../../constants/commonFunction";
import AcceptButtons from "../OpenCasesDetailsPage/AcceptButtons";
import UploadInvoiceFormBottom from "./UploadInvoiceFormBottom";
import { formData ,setvalueFetch} from "./actions";
import { ApiDispatchContext } from "../../context/ApiContext";
import ErrorAlert from "../../components/ErrorAlert";
import FilterPopUp from "../../components/FilterPopUp";
import DocumentUpload from "../../components/DocumentUpload";
import close from "../../images/close.png";
import SuccessMessage from "../../components/SuccessMssgDisplay";
import GenricPetitionerForm from "../../components/PetitionerForm/GenricPetitionerForm";
import Loader from "../../components/Loader";
import { useNavigate } from 'react-router-dom';
import { utList } from "../../constants/unionTerritories";
import './index.css';

export function UploadInvoice({addNewInvoice,setFormData,redirectPath,newsetvalueFetch,valuefetched, deleteOwnFileCheck=true, employeeId}) {
  useInjectReducer({ key: "uploadInvoice", reducer });
  const [modal, setModal] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [modalBody, setModalBody] = useState("");
  const [open, setOpen] = useState(false);
  const [chargesTotal, setChargesTotal] = useState(0);
  const [cgstAmount, setCgstAmount] = useState(0);
  const [sgstAmount, setSgstAmount] = useState(0);
  const [ugstAmount, setUgstAmount] = useState(0);
  const [totalAmount, setTotalAmount] = useState(0);
  const [gstApplicable, setGstApplicable] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [allParameterValuesNotEmpty, setAllParameterValuesNotEmpty] = useState(false);
  const [renamed_block_parameter, setrenamed_block_parameter] = useState('');
  const [fetched, setFetched] = useState(false);
  const [docsUploaded,setDocsUploaded] = useState(false);
  const toggle = () => setModal(!modal);
  const api = useContext(ApiDispatchContext);
  let caseid = Number(getUrlParameter('case'));
  let casetype = getUrlParameter('casetype');
  let subProduct = getUrlParameter('subProduct');
  const gstPercent = 9;
  const removeSpecialCharRegex = /^[a-zA-Z0-9\s]*$/;

  const navigate = useNavigate();

  const employeeDetails = JSON.parse(localStorage.getItem("Employment-master"));
  const vendorLocation = employeeDetails?.employee?.vendor_state;

  useEffect(() => {
    setShowLoader(true);
    setFormData([]);
    newsetvalueFetch(false);
    window.scrollTo(0, 0);
    api.addNewInvoiceForm({ caseId: caseid }).then(data => {
      if (data?.data && data?.data?.data) {
        let newres = data?.data?.data;
        const isGstApplicable = newres?.singleInvoiceParameters?.some(parameter => {
            if (parameter?.block_parameters_id === 7) {
                return parameter?.parameter_value === "Yes";
            }
        });
        setGstApplicable(isGstApplicable)
    
        let tempdata = {};
        Object.assign(tempdata, newres);
        tempdata.documents = tempdata?.documents ? tempdata?.documents : [];
        setFormData(tempdata);
        setFetched(true);
        newsetvalueFetch(true);
        setShowLoader(false);
      } else {
        setModalTitle("Error");
        if (data?.data?.error && data?.data?.message) {
          setModalBody(data?.data?.message)
        }
        else {
          setModalBody('Please try after sometime');
        }
        setShowLoader(false);
        toggle();
      }
    }).catch(err => {
      setModalTitle("Error");
      if (err && err?.response && err?.response?.data && err?.response?.data?.message) {
        setModalBody(err?.response?.data?.message);
      } else {
        setModalBody('Please try after sometime.');
      }
      setShowLoader(false);
      toggle();
    })
  }, []);

  useEffect(() => {
    if(valuefetched && addNewInvoice?.formData && addNewInvoice?.formData?.singleInvoiceParameters?.length > 0 && addNewInvoice?.formData?.multipleInvoiceParameters?.length > 0){
      const isAllSingleValuesNotEmpty = addNewInvoice?.formData?.singleInvoiceParameters.every(obj => {
        if(obj?.parameter_type != "upload"){
          if(!gstApplicable){
             if(obj?.parameter_name !=="gst_number"){
              return  (obj?.value != null && obj?.value !== "") || (obj?.parameter_value != null && obj?.parameter_value !== "")
             }
          } else {
            (obj?.value != null && obj?.value !== "") || (obj?.parameter_value != null && obj?.parameter_value !== "")
          } 
        }
        return true
      });
      const isAllMultiValuesNotEmpty = addNewInvoice?.formData?.multipleInvoiceParameters.every(arr => {
        return arr.every(obj =>obj?.parameter_value != null && obj?.parameter_value !== "");
      });
      setAllParameterValuesNotEmpty(isAllSingleValuesNotEmpty && isAllMultiValuesNotEmpty && addNewInvoice?.formData?.documents && addNewInvoice?.formData?.documents?.length)
    }
  }, [addNewInvoice?.formData])

  useEffect(() => {
    let gstData = gstApplicable ? (chargesTotal*gstPercent)/100 : 0;
    if(vendorLocation && utList.includes(vendorLocation.toLowerCase())) {
      setUgstAmount(gstData + gstData);
      setTotalAmount(chargesTotal + gstData + gstData);
    } else{
      setCgstAmount(gstData);
      setSgstAmount(gstData);
      setTotalAmount(chargesTotal + gstData + gstData);
    }
  }, [chargesTotal, gstApplicable])

  const filterToggle = (temprenamed_block_parameter) => {
    setrenamed_block_parameter(temprenamed_block_parameter);
    setOpen(!open);
  }

  const handleChangeSetValue = (newPetitioners,value,index) => {
    newPetitioners.singleInvoiceParameters[index].parameter_value = value; // update the parameter_value of the singleParameter at the given index
    setFormData(newPetitioners); // set the new state object
    if (newPetitioners?.singleInvoiceParameters[index]?.parameter_name == "gst_applicable") {
      if (newPetitioners?.singleInvoiceParameters[index]?.parameter_value == "Yes") {
        setGstApplicable(true);
      } else {
        setGstApplicable(false);
      }
    }
  }

  const handleChange = (value, index) => {
    let newPetitioners = { ...addNewInvoice.formData }; // create a copy of the petitioners object
    if (newPetitioners?.singleInvoiceParameters[index]?.parameter_name === "invoice_no") {
      if (removeSpecialCharRegex.test(value)) {
        handleChangeSetValue(newPetitioners, value, index)
      } else {
        // not a valid character
      }
    } else {
      handleChangeSetValue(newPetitioners, value, index)
    }
  };

  const handlePetChange = (value, petIndex, petInpIndex, parameter_name) => {
    let newPetitioners = { ...addNewInvoice?.formData };
    if(parameter_name === "charge_amount"){
      const decimalPart = value.toString().split(".")[1];
      if (!decimalPart || decimalPart?.length <= 2) {
        newPetitioners.multipleInvoiceParameters[petIndex][petInpIndex].parameter_value = value
      }
    }
    else{
      newPetitioners.multipleInvoiceParameters[petIndex][petInpIndex].parameter_value = value
    }
    setFormData(newPetitioners);
    if(parameter_name && parameter_name === "charge_amount") {
      let charges = 0;
      newPetitioners?.multipleInvoiceParameters?.forEach(item => {
        charges = Number(item[1]?.parameter_value) + charges;
      })
      setChargesTotal(charges);
    }
  }

  function getFormDataWithMultiParams() {
    let result = {};
    const temp = [];
    let doSubmit = true;
    addNewInvoice?.formData?.singleInvoiceParameters.forEach(pay => {
      if(pay?.parameter_name == "product_category" || (gstApplicable == true && pay?.parameter_name !== "gst_number")) {
        if(pay?.parameter_value === "" || pay?.parameter_value == null) {
          setModalTitle("Error");
          setModalBody("Please fill all the required fields");
          toggle();
          doSubmit = false;
          return
        }
      } 
      if (pay?.parameter_type !== "upload") {
        result[pay?.parameter_name] = pay?.parameter_value ? pay?.parameter_value : null;
      }
    });
    addNewInvoice?.formData?.multipleInvoiceParameters.map(item => {
      let obj = {};
      item.map(ele => {
        obj[ele.parameter_name] = ele?.parameter_value ? ele?.parameter_value : null;
      });
      temp.push(obj);
    });
    const multipleParameter = "multipleParameter";
    result[multipleParameter] = temp;
    result["charge_total"] = chargesTotal;
    result["cgst"] = cgstAmount;
    result["sgst"] = sgstAmount;
    result["ugst"] = ugstAmount;
    result["total_amount"] = totalAmount.toFixed(2);
    if(doSubmit) {
      return result;
    } else {
      return result = null;
    }
  }

  const getdocumentsArray = () => {
    let tempdata = [];
    let config = [];
    Object.assign(tempdata, addNewInvoice?.formData);
    if (tempdata?.documents) {
      return tempdata?.documents;
    } else {
      return config;
    }
  }

  const onSubmit = () => {
    setShowLoader(true);
    setShowSuccessMessage(false);
    const formData = getFormDataWithMultiParams();
    if(formData) {
      const payload = {
        "caseId": caseid,
        "formData": formData,
        "documents": getdocumentsArray(),
        "rejectedInvoice":false,
        "invoiceType": casetype === "opencase" || casetype === "allottedcase" ? "open" : "reinvestigated"
      }
      api.submitNewInvoiceForm(payload).then(data => {
        if(data?.data && data?.data?.success) {
          setFormData();
          const stateVal = path.UPLOAD_INVOICE_DETAILS+'?'+btoa('case='+caseid+'&&casetype=' +casetype+'&success=successTrue'+ '&subProduct=' + subProduct)
          setShowLoader(false);
          navigate(
              path.SUCCESS_PAGE,
              {state: {"stateVal" : stateVal }}
            );
        } else {
          setModalTitle("Error");
          if(data?.data?.error && data?.data?.message){
            setModalBody(data?.data?.message)
          }
          else{
            setModalBody('Please try after sometime');
          }
          setShowLoader(false);
          toggle();
        }
      }).catch(err => {
        setModalTitle("Error");
        if (err && err?.response && err?.response?.data && err?.response?.data?.message) {
          setModalBody(err?.response?.data?.message);
        } else {
          setModalBody('Please try after sometime.');
        }
        setShowLoader(false);
        toggle();
      })
    }
  }

  const uploadDocuments = async(file, filetype) => {
    setShowLoader(true);
    await api.uploadDocuments(file).then(data => {
      if (data?.data && data?.data?.success) {
        let tempdata = {};
        let newPetitioners = { ...addNewInvoice?.formData }; 
        Object.assign(tempdata, newPetitioners);
        let newdata = {
          document_name: data?.data?.data?.document_name,
          document_path: data?.data?.data?.document_path,
          document_size: data?.data?.data?.document_size,
          document_type: filetype,
        }
        tempdata.documents.push(newdata)
        setFormData(tempdata);
        setOpen(!open);
        setShowLoader(false);
      } else {
        setModalTitle("Error");
        if(data?.data?.error && data?.data?.message){
          setModalBody(data?.data?.message)
        } else {
          setModalBody('Please try after sometime');
        }
        toggle();
        setShowLoader(false);
      }
    }).catch(err => {
      setModalTitle("Error");
      if(err && err?.response && err?.response?.data && err?.response?.data?.message){
        setModalBody(err?.response?.data?.message);
      } else {
        setModalBody('Please try after sometime');
      }
      toggle();
      setShowLoader(false);
   });
 }

  const onClose = (e, index) => {
    e.stopPropagation();
    let tempArray = JSON.parse(JSON.stringify(addNewInvoice?.formData));
    let tempDocs = JSON.parse(JSON.stringify(tempArray?.documents));
    tempDocs.splice(index, 1);
    tempArray.documents = tempDocs
    setFormData(tempArray)
  }

  const addRowClick = () => {
    let tempArray = JSON.parse(JSON.stringify(addNewInvoice?.formData));
    let temp = JSON.parse(JSON.stringify(tempArray?.multipleInvoiceParameters[0]));
    if (temp) {
      temp.forEach(item => {
        item.parameter_value = "";
      });
    }
    tempArray?.multipleInvoiceParameters.push(temp);
    setFormData(tempArray);
  }

  const removeRowClick = (index) => {
    let tempArray = JSON.parse(JSON.stringify(addNewInvoice?.formData));
    let temp = JSON.parse(JSON.stringify(tempArray?.multipleInvoiceParameters));
    temp.splice(index, 1);
    tempArray.multipleInvoiceParameters = temp;
    let charges = 0;
    tempArray?.multipleInvoiceParameters.forEach(item => {
      charges = Number(item[1]?.parameter_value) + charges;
    })
    setChargesTotal(charges);
    setFormData(tempArray);
  }

  function backgroundColor() {
    return casetype === "opencase" ? "rgba(26, 178, 117, 0.1)" : casetype === "allottedcase" ? 'rgba(5, 138, 238, 0.1)' : "rgba(235, 175, 0, 0.1)"
  }

  function formColor(){
    return casetype === "opencase" ? "open-case-color" : casetype === "allottedcase" ? "allotted-case-color" : "rein-case-color";
  }

  function borderColor() {
    return casetype === "opencase" ? " 1px solid #1AB275" : casetype === "allottedcase" ? "1px solid #058AEE" : "1px solid #EBAF00";
  }

  return(
    <div className={"newInvoice-wrapper newUploadInvoice"+casetype}>
      <CommonNavbar subProduct={subProduct} title="New Invoice" navbarname={casetype==="opencase"?'AssignedBlocksOpenCases':casetype==="reinvestigatecases"?"AssignedBlocksReinCases":"AssignedBlocksAllottedCases"} />
      <div className="input-container">
        {addNewInvoice?.formData && addNewInvoice?.formData?.singleInvoiceParameters  && addNewInvoice?.formData?.singleInvoiceParameters?.length > 0 && addNewInvoice?.formData?.singleInvoiceParameters?.map((ele,index) => {
          if(index === 0) {
              return <div style={{marginLeft: "10px", paddingTop: "15px"}}> <p style={{fontWeight: 500, fontSize: "14px"}}>Reference # {ele?.parameter_value}</p></div>
          } else if(ele.parameter_name === "product_category") {
            return <GenricPetitionerForm isDisabled={false} ifRequired={true} key={index} config={ele} formcolor={backgroundColor()} formName={formColor()} handleChange={(event) => handleChange(event, index)} showLabel={true} />
          } else if(ele.parameter_name === "gst_number") {
            if(ele.parameter_value) {
            return <GenricPetitionerForm isDisabled={true} ifRequired={true} key={index} config={ele} formcolor={backgroundColor()} formName={formColor()} handleChange={(event) => handleChange(event, index)} showLabel={true} />
            } else {
              return null;
            }
          } else if(ele?.parameter_name === "gst_applicable") {
            return <GenricPetitionerForm isDisabled={true} ifRequired={true} key={index} config={ele} formcolor={backgroundColor()} formName={formColor()} handleChange={(event) => handleChange(event, index)} showLabel={true} />
          } else {
            return <GenricPetitionerForm isDisabled={false} ifRequired={true} key={index} config={ele} formcolor={backgroundColor()} formName={formColor()} handleChange={(event) => handleChange(event, index)} showLabel={true} />
          }
        })}
        {addNewInvoice?.formData && addNewInvoice?.formData?.multipleInvoiceParameters && addNewInvoice?.formData?.multipleInvoiceParameters?.length > 0 && addNewInvoice?.formData?.multipleInvoiceParameters?.map((pet,petIndex) => {
         return (
            <div className="multiField-row" key={petIndex}>
              <div className="serialNum">
                <label>Sr. No.</label>
                <p>{petIndex+1}</p>
              </div>
              {Array.isArray(pet) && pet.map((petInp, petInpIndex) => {
                if(petInp?.parameter_name === "charge_amount") {
                  return <GenricPetitionerForm isDisabled={false} ifRequired={true} key={petInpIndex} config={petInp} formcolor={backgroundColor()} formName={formColor()} handleChange={(value) => handlePetChange(value, petIndex, petInpIndex, petInp?.parameter_name)} showLabel={true} />
                } else {
                  return <GenricPetitionerForm isDisabled={false} ifRequired={true} key={petInpIndex} config={petInp} formcolor={backgroundColor()} formName={formColor()} handleChange={(value) => handlePetChange(value, petIndex, petInpIndex)} showLabel={true} />
                }
              })}
              {petIndex !== 0 && <button data-testid="AddNewInvoice1" className='closeIcon' onClick={() => removeRowClick(petIndex)}><img src={close} alt="close" /></button>}
            </div>
            )
          })}
        <div className="addRowWrapper">
          <button data-testid="AddNewInvoice2" onClick={() => addRowClick()} className="addRow">+Add row</button>
        </div>
        <div className="chargesDetail">
          <div className="chargesDetail-row">
            <p>Charge Total</p>
            <p>{chargesTotal.toFixed(2)}</p>
          </div>
          {vendorLocation && utList.includes(vendorLocation.toLowerCase()) ? (
            <div className="chargesDetail-row">
              <p>UGST</p>
              <p>{ugstAmount.toFixed(2)}</p>
            </div>
          ) : (
            <>
              <div className="chargesDetail-row">
                <p>CGST</p>
                <p>{cgstAmount.toFixed(2)}</p>
              </div>
              <div className="chargesDetail-row">
                <p>SGST</p>
                <p>{sgstAmount.toFixed(2)}</p>
              </div>
            </>
          )}
          <div className="chargesDetail-row">
            <p>Total Amount</p>
            <p>{totalAmount.toFixed(2)}</p>
          </div>
        </div>
        <UploadInvoiceFormBottom border={borderColor()} onClose={(e,i)=>onClose(e,i)} docs={addNewInvoice?.formData ? addNewInvoice?.formData : null} filterToggle={filterToggle} showUploadBtn={true} />
        <div className="AcceptButtons-ContainerParent">
          <div className="AcceptButtons-Container">
            <AcceptButtons disabled={!allParameterValuesNotEmpty} background={allParameterValuesNotEmpty?"rgb(233, 77, 81)":"#F7BDBE"} color={allParameterValuesNotEmpty?"rgb(255, 255, 255)":"#E42125"} datatestid="AddNewInvoice3" onClick={onSubmit}>Submit</AcceptButtons>
          </div>
        </div>
      </div>
      {open && (<FilterPopUp open={open} backgroundColor={'#8F949B80'} filterToggle={filterToggle} >
        <DocumentUpload uploadDocuments={uploadDocuments} docsUploaded={docsUploaded} setDocsUploaded={setDocsUploaded}/>
      </FilterPopUp>)}
      {showLoader && <Loader />}
      {showSuccessMessage && (
        <SuccessMessage message="Records Updated Successfully!" delay={3000} />
      )}
      <ErrorAlert
        modal={modal}
        toggle={toggle}
        modalTitle={modalTitle}
        modalBody={modalBody}
      />
    </div>
  );
}

UploadInvoice.propTypes = {
  dispatch: PropTypes.func.isRequired
};

const mapStateToProps = createStructuredSelector({
  addNewInvoice: makeSelectUploadInvoice(),
  valuefetched:setvaluefetched(),
});

export function mapDispatchToProps(dispatch) {
  return {
    setFormData: (emp) => dispatch(formData(emp)),
    newsetvalueFetch : (emp2) => dispatch(setvalueFetch(emp2)),
    redirectPath: (path) => dispatch(push(path))
  };
}

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps
);

export default compose(withConnect, memo)(UploadInvoice);