import React, { useEffect, useState, useRef } from "react";
import heic2any from "heic2any";
import { Progress } from "reactstrap";
import ErrorAlert from "../ErrorAlert";
const HeicViewer = ({ selectedDoc }) => {
  const [progress, setProgress] = useState(0);
  const [loading, setLoading] = useState(false);
  const [modal, setModal] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [modalBody, setModalBody] = useState("");
  const imageRef = useRef(null);
  const toggle = () => setModal(!modal);
  useEffect(() => {
    if (!selectedDoc) return;
    setLoading(true);
    const timer = setInterval(() => {
      setProgress((prevProgress) => Math.min(prevProgress + 1, 99));
    }, 150);
    fetch(selectedDoc)
      .then((res) => res.blob())
      .then((blob) =>
        heic2any({
          blob,
          toType: "image/png",
          quality: 0.72,
        })
      )
      .then((conversionResult) => {
        clearInterval(timer);
        setProgress(100);
        const url = URL.createObjectURL(conversionResult);
        setTimeout(() => {
          if (imageRef.current) {
            imageRef.current.src = url;
          }
          setLoading(false);
        }, 150);
      })
      .catch((error) => {
        setModalTitle("Error");
        if (error) {
          setModalBody(error.message);
        } else {
          setModalBody("Please try after sometime");
        }
        toggle();
        setLoading(false);
        clearInterval(timer);
      });
    return () => {
      clearInterval(timer);
    };
  }, [selectedDoc]);
  return (
    <div>
      {loading && (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            mt: "20%",
          }}
        >
          <div style={{ width: "95%", height: "20px", mr: 1 }}>
            <Progress
              variant="determinate"
              value={progress}
              sx={{ height: "20px", borderRadius: "10px" }}
            />
          </div>
          <div style={{ minWidth: 35 }}>
            <div fontSize="3rem" color="primary">
              Loading...{`${Math.round(progress)}%`}
            </div>
          </div>
        </div>
      )}
      <ErrorAlert
        modal={modal}
        toggle={toggle}
        modalTitle={modalTitle}
        modalBody={modalBody}
      />
      <img
        ref={imageRef}
        alt="Converted HEIC"
        style={{
          display: loading ? "none" : "block",
          width: "100%",
        }}
      />
    </div>
  );
};
export default HeicViewer;