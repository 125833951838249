import React from 'react';
import "./index.css";
import LoaderGif from "../../images/loaderVideo.gif";

const Loader = () => {
  return (
    <div className='loaderWrapper'>
        <img src={LoaderGif} alt="loader" className='loaderGif' />
    </div>
  )
}

export default Loader;