import React, { useEffect, useState, useContext } from 'react';
import { ApiDispatchContext } from "../../../context/ApiContext";
import ErrorAlert from "../../../components/ErrorAlert";
import Icon from '../../OpenCases/CaseCard/Icon';
import { getUrlParameter } from "../../../constants/commonFunction";
import UploadedFileView from '../../../components/UploadedFileView';

export const Claim = (props) => {
  const [ClaimData, setClaim] = useState("");
  const [docs, setDocs] = useState([]);
  const api = useContext(ApiDispatchContext);
  const [modal, setModal] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [modalBody, setModalBody] = useState("");
  const toggle = () => setModal(!modal);
  const [itemsToDisplay, setitemsToDisplay] = useState("");
  const [loaddata, setloaddata] = useState(false);
  const [showAll, setShowAll] = useState(false);
  let casid = Number(getUrlParameter('case'));

  useEffect(() => {
    if (casid) {
      let payload = {
        "caseId": casid,
        "segmentType": "claim"
      }
      api.getCaseBPRSegmentDetails(payload).then(data => {
        if (data?.data && data?.data?.success && data?.data?.data) {
          setloaddata(true);
          setClaim(data?.data?.data?.claimResult);
          setDocs(data?.data?.data?.publicUrl);
          let tempdata = [];
          Object.assign(tempdata, data?.data?.data?.claimResult);
          tempdata.forEach((item, i) => {
            tempdata[i].showviewbutton = true;
          });
          setitemsToDisplay(tempdata);
        }
      }).catch(err => {
        setModalTitle("Failed to load claim Data.");
        if (err && err.response && err.response.data && err.response.data.message) {
          setModalBody(err.response.data.message);
        } else {
          setModalBody('Please try after sometime.');
        }
        toggle();
      })
    }
  }, [casid]);

  const handleShowAll = (index) => {
    let payload = {
      "caseId": casid,
      "claimAllProductId": itemsToDisplay[index][1]?.value
    }
    api.getCaseClaimSegmentDetails(payload).then(data2 => {
      if (data2.data && data2.data.success) {
        let tempdata = [];
        Object.assign(tempdata, itemsToDisplay);
        tempdata[index] = data2?.data?.data?.data;
        tempdata[index].documents =  data2?.data?.data?.documents;
        tempdata[index].showviewbutton = false;
        setitemsToDisplay(tempdata);
        setShowAll(true);
      }
    }).catch(err => {
      setModalTitle("Failed to load claim Data.");
      if (err && err.response && err.response.data && err.response.data.message) {
        setModalBody(err.response.data.message);
      } else {
        setModalBody('Please try after sometime.');
      }
      toggle();
    })
  };

  const handleShowless = (index) => {
    let tempdata = [];
    Object.assign(tempdata, itemsToDisplay);
    tempdata[index] = ClaimData[index];
    setitemsToDisplay(tempdata);
    setShowAll(false);
  }

  return (
    <div className='claim-data claim-mains'>
      {loaddata ?
        <div className='claim-div-data'>
          {itemsToDisplay && itemsToDisplay.map((item1, index1) => {
            return (
              <div className='claim-div-data'>
                <div className='rein-claim-maindiv'>
                {item1 && !item1?.showviewbutton  && (
                    <div className='claim-upbuttton-mainstyle'>
                      <button data-testid="handleShowless1-view" className='claim-upbuttton-style' onClick={() => { handleShowless(index1); }}><img className='claim-upbuttton-img' src={require('../../../images/rein-claim-up.png')} /></button>
                    </div>
                  )}
                  <div className='claim-data '>
                    {Object.values(item1).map((item, index) => {
                      let textAlign = "left";
                      let width = "33.33%";
                      let marginTop = "17px";
                      let displayItem = true;
                      if (item?.label === "claim_all_product_id" || item?.label === undefined) {
                        displayItem = false;
                      }
                      if (item1?.length < 7) {
                        if (index === 0) {
                          textAlign = "left";
                          marginTop = "7px";
                        } else if (index === 2) {
                          marginTop = "7px";
                          textAlign = "left";
                        } else if (index === 3) {
                          marginTop = "7px";
                          textAlign = "right";
                        }
                      }
                      const divStyle = {
                        textAlign: textAlign,
                        width: width,
                        marginTop: marginTop,
                      };
                      if (displayItem) {
                        return (
                          <div className="claim-map-data-breakDIV" style={divStyle}>
                            <div className="map-data-text1">{item?.label}</div>
                            <div className="map-data-text2">{item?.value ? item?.value : '-'}</div>
                          </div>
                        );
                      }
                    })}
                    {item1?.showviewbutton  ? (
                      <div className='map-data-breakDIV claimbtn-main'>
                        <button data-testid="handleShowAllbtn-view" className='claimview-btn' onClick={() => { handleShowAll(index1); }}><Icon />View</button>
                      </div>
                    ) : null}
                  </div>
                  {item1 && !item1?.showviewbutton > 0 && docs?.length > 0 &&  (
                    <>
                      <hr />
                      <span className='Claimdocument-title'>Documents</span>
                      <div className="docs-container">
                          <UploadedFileView download={true} border="1px solid #EBAF00" documentList={docs}  />
                      </div>
                    </>
                  )}
                  {item1 && !item1?.showviewbutton  && (
                    <div className='claim-upbuttton-mainstyle2'>
                      <button data-testid="handleShowless2-view" className='claim-upbuttton-style' onClick={() => { handleShowless(index1); }}><img className='claim-upbuttton-img' src={require('../../../images/rein-claim-up.png')} /></button>
                    </div>
                  )}
                </div>
              </div>
            );
          })}
        </div>
        : null}
      <ErrorAlert
        modal={modal}
        toggle={toggle}
        modalTitle={modalTitle}
        modalBody={modalBody}
      />
    </div>
  )
}

Claim.propTypes = {}

export default Claim;