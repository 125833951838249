import './index.css';
import ctrlLogo from "../../images/ctrlLogo.png";
function Footer() {
  return (
    <div style={{ textAlign: 'center',height: 100}} className='mobileotpbottommargin'>
      <section>
        <div className="imageCTRLMain">
          <div className='imageCtrlFlex'>
            <p className='imageCTRLBtn imagectrlNewbtn'>Powered by </p>
            <img src={ctrlLogo} alt="imgage" className='imageCTRLBtnStyle' />
          </div>
        </div>
      </section>
    </div>
  );
}

export default Footer;
