import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import "./card.css";
import { Card, CardBody, Row } from 'reactstrap';
import CardFields from './CardFields';
import Icon from './Icon';
import { OPEN_CASE_DETAILS } from '../../../constants/pathConstants';
import path from '../../../constants/pathConstants';
import { createStructuredSelector } from 'reselect';
import { makeSelectCaseSearchValue } from '../../SearchCase/selectors';
import { compose } from 'redux';
import CircleTick from '../../../images/white-circle-tick.svg';
import { makeMultiSelectCases } from '../selectors';
import { getEmployeeRole } from '../../App/selectors';
import { useNavigate } from 'react-router-dom';

export const CardComponent = ({ cardDetail, btn, showCaseType, searchValue, showSelect, handleMultiCaseSelect, caseidArr, employeeRole }) => {
  const navigate = useNavigate();
  const navigateToDetails = (caseNum, type) => {
    const subProduct = type.substr(-2)
    navigate(path.OPEN_CASE_DETAILS + '?' + btoa('case='+caseNum+'&subProduct=' + subProduct));
  }
  return (
    <>
      <Card className={showSelect && !caseidArr?.includes(cardDetail[0]?.value) ? "select-card-wrap" : ""}>
        {showSelect && employeeRole === "vendor" && <button data-testid="handleMultiCaseSelect" className='card-shadow' onClick={() => handleMultiCaseSelect(cardDetail[1]?.value)}>
          <div className='center-circle'>
            {caseidArr?.includes(cardDetail[1]?.value) && <img src={CircleTick} alt="CircleTick" />}
          </div>
        </button>}
        {showCaseType && <div className='caseTypeNameOpen'>Open</div>}
        <CardBody style={{position:"relative"}}>
          <Row style={{padding:0,margin:0}}>
            <div className='card-case'>
              <span className='card-case-lable'>{cardDetail[1]?.label} :</span> <span className={(searchValue && cardDetail[0]?.value == searchValue) ? 'card-case-value highlight' : 'card-case-value'}>{cardDetail[0]?.value ? cardDetail[0]?.value : "-"}</span>
            </div>
              {cardDetail?.length && cardDetail?.map((item, index) => (index > 1) &&
              <CardFields key={item?.label} item={item} index={index} /> )}
          </Row>
          {btn &&
            <div className='details-view-btn'>
              <button data-testid="btn-view" className='buttonopencases' onClick={()=>navigateToDetails(cardDetail[1]?.value, cardDetail[8]?.value)}><Icon />  View</button>
            </div>}
        </CardBody>
      </Card>
    </>
  )
}

CardComponent.propTypes = {
  // cardDetail: PropTypes.array
  searchValue: PropTypes.string,
  caseidArr: PropTypes.array,
  employeeRole: PropTypes.string
}

const mapStateToProps = createStructuredSelector({
  searchValue: makeSelectCaseSearchValue(),
  caseidArr: makeMultiSelectCases(),
  employeeRole: getEmployeeRole()
});

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
  };
}

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps
);

export default compose(
  withConnect,
  memo
)(CardComponent);