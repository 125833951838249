import '../index.css';
import close from "../../../images/CloseErrorIcon.svg";
import path from '../../../constants/pathConstants';
import { useNavigate } from "react-router-dom";

export function DoNotAcceptSuccess() {
  const navigate = useNavigate();
  return (
    <div className='accept-success-page'>
        <img src={close} />
        <span className='accept-success-page-title'>You have not accepted this case</span>
        <button data-testid="DoNotAcceptSuccess" className='accept-success-page-btn' onClick={()=>navigate(path.OPEN_CASE)}>Back to case list</button>
    </div>
  )
}

export default DoNotAcceptSuccess;