import React from 'react';
import AlertIcon from './Alert-icon';
import Icon from './Icon';
import { Card, CardBody, Col, Row, Button } from 'reactstrap';
import CardFields from './CardFields';
import { useNavigate } from "react-router-dom";
import { REINVESTIGATE_CASES_DETAILS } from '../../../constants/pathConstants';
import './card.css';

export function CardComponent({ cardDetail,btn }) {
  const navigate = useNavigate();
  const navigateToDetails = (caseNum) => {
    navigate(REINVESTIGATE_CASES_DETAILS + '?' + btoa('case='+caseNum));
  }

  return (
    <div className='rein-caseCard'>
      <div className='card-case'>
        <span className='card-case-lable'>{cardDetail[1].label} :<span style={{paddingLeft:"6px"}} className='card-case-value'>{cardDetail[0].value ? cardDetail[0].value : "-"}</span></span>
        {/* <span className="icon-end">
          <img src={require('../../../images/alert.png')} />
        </span> */}
      </div>
      <div className='rein-caseCard-cardFields'>
      {cardDetail.length && cardDetail.map((item, index) => { if (index > 1) return <CardFields item={item} index={index} /> })}
        </div>
         {btn &&
            <div  className='rein-details-view-btn'>
              <button data-testid="btn-view" className='buttonreincases' onClick={()=>navigateToDetails(cardDetail[1].value)}><Icon />  View</button>
            </div>}
    </div>
  )
}

export default CardComponent;