import React, { useState, memo } from 'react';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { compose } from 'redux';
import path from '../../constants/pathConstants';
import './index.css';
import ComingSoonPopUp from '../ComingSoonPopUp';
import { useNavigate } from "react-router-dom";
export function CasesIcons({ filterpopupShow, caseType, downloadReport}) {
    const navigate = useNavigate();
    const [open,setOpen] = useState(false)
    const toggle = ()=>setOpen(!open)
    return (
        <div style={{flexDirection:'row'}}>
            <img data-testid="caseiconsSearch" onClick={()=>navigate(path.SEARCH_CASE  + "?" + btoa("caseType="+caseType))} style={{width:"16px",height:"16px",marginRight:"18px",cursor:"pointer"}} src={require('../../images/search4.png')} alt="search" />
            <img data-testid="search-icon2" onClick={filterpopupShow} style={{width:"16px",height:"18px",marginRight:"18px",cursor:"pointer"}}  src={require('../../images/filter4.png')} alt="filter" />
            {caseType !== "rejectedInvoice" && <img data-testid="search-icon3" onClick={downloadReport} style={{width:"16px",height:"16px",marginRight:"6px",cursor:"pointer"}}  src={require('../../images/download4.png')} alt="download" />}
            <ComingSoonPopUp open={open} toggle={toggle} />
        </div>
    )
}
CasesIcons.propTypes = {
};

const mapStateToProps = createStructuredSelector({
});

export function mapDispatchToProps(dispatch) {
    return {
    };
}

const withConnect = connect(
    mapStateToProps,
    mapDispatchToProps,
);

export default compose(withConnect, memo,)(CasesIcons);