import React, { useEffect, useState } from 'react';
import './index.css';
import file from '../../images/file.png';
import cross from '../../images/cross.svg';
import { useLocation } from 'react-router-dom';
export function DocumentUpload(props) {
  const [selectedFile, setSelectedFile] = useState(null);
  const [Audio, setAudio] = useState([]);
  const [Images, setImages] = useState([]);
  const [Video, setVideo] = useState([]);
  const [Documents , setDocuments] = useState([]);
  const [disable,setDisable] = useState(true)

  const currentPage = useLocation();
 
  useEffect(()=>{
    if(Audio?.length || Images?.length || Video?.length || Documents?.length){
      setDisable(false)
    }
    else{
      setDisable(true)
    }
  },[Audio,Images,Video,Documents])

  const handleSubmit = async (e) => {
    e.preventDefault();
    let completedFiles = 0;
    if (selectedFile) {
      for (let i = 0; i < selectedFile?.length; i++) {
        const formData = new FormData();
        formData.append('file', selectedFile[i]);
        await props.uploadDocuments(formData, selectedFile[i]?.type)
        completedFiles++;
      }
    }
    if(completedFiles == selectedFile?.length){
      props.setDocsUploaded(!props.docsUploaded)
    }
  };

  const handleFileChange = (e) => {
    const availableAudioType = ['MP3', 'M4A', 'AAC', 'FLAC', 'WAV', 'mp3', 'm4a', 'aac', 'flac', 'wav'];
    const files = e.target.files;
    const formData = [];
    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      let lastDotIndex = file?.name.lastIndexOf(".");
      const fileExt = file?.name.substring(lastDotIndex + 1);
      if(availableAudioType.includes(fileExt)) {
        formData.push(file);
      }
    }
    const updatedSelectedFiles = selectedFile ? [...selectedFile, ...formData] : formData;
    setAudio(prevMyFile => [...prevMyFile, ...formData]);
    setSelectedFile(updatedSelectedFiles);
  };

  const handleFileChange1 = (e) => {
    const availableImgType = ['JPG', 'JPEG', 'PNG', 'BMP', 'HEIF', 'SVG', 'HEIC', 'jpg', 'jpeg', 'png', 'bmp', 'heif', 'svg', 'heic'];
    const files = e.target.files;
    const formData = [];
    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      let lastDotIndex = file?.name.lastIndexOf(".");
      const fileExt = file?.name.substring(lastDotIndex + 1);
      if(availableImgType.includes(fileExt)) {
        formData.push(file);
      }
    }
    setImages(prev => [...prev,...formData]);
    const updatedSelectedFiles = selectedFile ? [...selectedFile, ...formData] : formData;
    setSelectedFile(updatedSelectedFiles);
  };
  
  const handleFileChange2 = (e) => {
    const availableVideoType = ['MP4', 'AVI', 'HEVC', 'mp4', 'avi', 'hevc'];
    const files = e.target.files;
    const formData = [];
    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      let lastDotIndex = file?.name.lastIndexOf(".");
      const fileExt = file?.name.substring(lastDotIndex + 1);
      if(availableVideoType.includes(fileExt)) {
        formData.push(file);
      }
    }
    setVideo(prev => [...prev,...formData]);
    const updatedSelectedFiles = selectedFile ? [...selectedFile, ...formData] : formData;
    setSelectedFile(updatedSelectedFiles);
  };

  const handleFileChange3 = (e) => {
    const availableDocType = ['DOCX', 'PDF', 'XLSX', 'docx', 'pdf', 'xlsx'];
    const files = e.target.files;
    const formData = [];
    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      let lastDotIndex = file?.name.lastIndexOf(".");
      const fileExt = file?.name.substring(lastDotIndex + 1);
      if(availableDocType.includes(fileExt)) {
        formData.push(file);
      }
    }
    setDocuments(prev => [...prev,...formData]);
    const updatedSelectedFiles = selectedFile ? [...selectedFile, ...formData] : formData;
    setSelectedFile(updatedSelectedFiles);
  };

  const onClose = (index,name,uploadtype) => {
    const updatedFiles = [...selectedFile];
    const newupdatedFiles = updatedFiles.filter((file) => file.name !== name);
    if(uploadtype === 'Audio'){
      setAudio(newupdatedFiles.filter((file) => file.type.includes("audio")));
    } else if(uploadtype === 'Images'){
      setImages(newupdatedFiles.filter((file) => file.type.includes("image")));
    } else if(uploadtype === 'Video'){
      setVideo(newupdatedFiles.filter((file) => {
        let lastDotIndex = file.name.lastIndexOf(".");
        let extension = file.name.substring(lastDotIndex + 1);
        return ['mp4', 'avi', 'hevc'].includes(extension);
      }));
    } else {
      setDocuments(newupdatedFiles.filter((file) => file.type.includes("application")));
    }
    setSelectedFile(newupdatedFiles);
  };
  
  return (
    <div className='documentUpload'>
      <span>Upload</span>
      <form onSubmit={handleSubmit} data-testid="DocumentUpload2">
        {currentPage?.pathname !== "/upload-investigation-report" && currentPage?.pathname !== "/upload-invoice" && (
          <div className='documentupload-main' >
            <input type="file" name="Audio" data-testid="DocumentUpload1" onChange={handleFileChange} className="custom-file-input" multiple accept=".mp3,.m4a,.aac,.flac,.wav" />
            <p className='supported-format-list'>MP3, M4A, AAC, FLAC, WAV</p>
            {Audio && Audio.map((item, i) => (
                  <div key={i} className='documentUploadassign-docs' data-testid="UploadInvoiceFormBottomfilter2">
                    <div style={{ display: 'flex' }}>
                      <div className='rejected-docs-remove'><img src={cross} onClick={(e) => onClose(i,item?.name,'Audio')} data-testid="UploadInvoiceFormBottomfilter3" /></div>
                      <img style={{ height: 20, width: 15 }} src={file} />
                      <div className="documentUploadassign-docs-right">
                        <div className='documentUploadassign-docs-name'>{item?.name?.length < 11 ? item?.name : item?.name?.slice(0, 10) + "..."}</div>
                        <div className='documentUploadassign-docs-size'>{item?.size}</div>
                      </div>  
                    </div>
                  </div>
                ))
            }
          </div>
        )}
        <div className='documentupload-main'>
          <input type="file" name="Images"  data-testid="DocumentUpload5" onChange={handleFileChange1} className="Imagescustom-file-input" multiple accept=".jpg,.jpeg,.png,.bmp,.heif,.svg,.heic" />
          <p className='supported-format-list'>JPG, JPEG, PNG, BMP, HEIF, SVG, HEIC</p>
          {Images && Images.map((item, i) => (
                <div key={i} className='documentUploadassign-docs' data-testid="UploadInvoiceFormBottomfilter2">
                  <div style={{ display: 'flex' }}>
                    <div className='rejected-docs-remove'><img src={cross} onClick={(e) => onClose(i,item?.name,'Images')} data-testid="UploadInvoiceFormBottomfilter3" /></div>
                    <img style={{ height: 20, width: 15 }} src={file} />
                    <div className="documentUploadassign-docs-right">
                      <div className='documentUploadassign-docs-name'>{item?.name?.length < 11 ? item?.name : item?.name?.slice(0, 10) + "..."}</div>
                      <div className='documentUploadassign-docs-size'>{item?.size}</div>
                    </div>
                  </div>
                </div>
              ))
            }
        </div>
        {currentPage.pathname !== "/upload-investigation-report" && currentPage.pathname !== "/upload-invoice" && (
          <div className='documentupload-main'>
          <input type="file" name="Video"  data-testid="DocumentUpload3" onChange={handleFileChange2} className="Videocustom-file-input" multiple accept=".mp4,.avi,.hevc" />
          <p className='supported-format-list'>MP4, AVI, HEVC</p>
          {Video && Video.map((item, i) => (
                  <div key={i} className='documentUploadassign-docs' data-testid="UploadInvoiceFormBottomfilter2">
                    <div style={{ display: 'flex' }}>
                      <div className='rejected-docs-remove'><img src={cross} onClick={(e) => onClose(i,item?.name,'Video')} data-testid="UploadInvoiceFormBottomfilter3" /></div>
                      <img style={{ height: 20, width: 15 }} src={file} />
                      <div className="documentUploadassign-docs-right">
                        <div className='documentUploadassign-docs-name'>{item?.name?.length < 11 ? item?.name : item?.name?.slice(0, 10) + "..."}</div>
                        <div className='documentUploadassign-docs-size'>{item?.size}</div>
                      </div>
                    </div>
                  </div>
                ))
            }
          </div>
          )}
        <div className='documentupload-main'>
          <input type="file" name="Documents"  data-testid="DocumentUpload4" onChange={handleFileChange3} className="Documentscustom-file-input" multiple accept=".docx,.pdf,.xlsx" />
          <p className='supported-format-list'>DOCX, PDF, XLSX</p>
          {Documents && Documents.map((item, i) => (
                <div key={i} className='documentUploadassign-docs' data-testid="UploadInvoiceFormBottomfilter2">
                  <div style={{ display: 'flex' }}>
                    <div className='rejected-docs-remove'><img src={cross} onClick={(e) => onClose(i,item?.name,'Documents')} data-testid="UploadInvoiceFormBottomfilter3" /></div>
                    <img style={{ height: 20, width: 15 }} src={file} />
                    <div className="documentUploadassign-docs-right">
                      <div className='documentUploadassign-docs-name'>{item?.name?.length < 11 ? item?.name : item?.name?.slice(0, 10) + "..."}</div>
                      <div className='documentUploadassign-docs-size'>{item?.size}</div>
                    </div>
                  </div>
                </div>
              ))
            }
        </div>
        <button disabled={disable} className={disable?'submitbuttonupload disable':'submitbuttonupload'} type="submit">Submit</button>
      </form>
    </div>
  )
}

export default DocumentUpload;